import { Component, OnInit } from '@angular/core';
import { common } from './View/common/constants/constant';
import { AlertService } from './View/common/alert/alert.service';
import { Router, NavigationStart } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { DataService } from './View/common/data.service';
import { SharedService } from './View/service/shared.service';
import { USERTYPE } from 'src/app/View/common/constants/constant';
import { mergeMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { LoaderService } from './View/common/preloader/loader.service';
import { AnalyzeSampleGridService } from './View/core/analyze-sample/analyze-sample-grid/analyze-sample-grid.service';
import { AnalyzeSampleInformationService } from './View/core/analyze-sample/analyze-sample-information/analyze-sample-information.service';

declare global {
  interface Navigator {
    msSaveOrOpenBlob: (blobOrBase64: Blob | string, filename: string) => void
  }
}
const IfIENotified = "IfIENotified";
const Yes = "Y";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  alertAt = 120;
  startTimer = true;
  loginStatus = false;
  ifIE = false;
  showHeaderFooter: boolean = false;
  Information = common.browserNotification;
  id_token: string;
  userRole: string = '';
  loginFlag = false;
  index = 0;
  companyNameIAM:any;
  companyIdIAM:any;
  companyIdList: any;
  constructor(private alertservice: AlertService, private loader: LoaderService, private cookieService: CookieService,
    private router:Router, private dataService: DataService, private sharedService: SharedService, private titleServ: Title, 
    private analyzeSampleService: AnalyzeSampleGridService, private alert: AlertService, private analyzeSampleInfoServ: AnalyzeSampleInformationService) {    
    this.alertservice.setalerttype(false);
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event['url'] == '/userType') {
          this.showHeaderFooter = true;
        } else {
          this.showHeaderFooter = false;
        }
      }
    });
  }

  ngOnInit() {
    this.loader.show(); 
    this.sharedService.getUserInfo().subscribe((userInfo)=>{       
      if(userInfo.hasOwnProperty('roleName')){        
        this.loader.hide();        
        this.userRole = userInfo.roleName;
      }  
    });
    
    var ie = localStorage.getItem(IfIENotified);
    if (window.navigator.msSaveOrOpenBlob && (ie == "" || ie == null)) {
      localStorage.setItem(IfIENotified, Yes);
      this.ifIE = true;
    }

    // if(window.location.href.includes('emailSampleReport') && 
    //   (environment.Req_Server.includes(environment.externalUrl) || environment.Req_Server.includes('localhost'))){
    //   let sampleId = window.location.href.split("/").pop();
    //   localStorage.setItem('emailSampleReport', sampleId);
    //   console.log("emailSampleReport 72, sampleID:",sampleId );
    //   this.navigateToViewReport(localStorage.getItem('emailSampleReport'));
    //   console.log("emailSampleReport 74,:",localStorage.getItem('emailSampleReport'));
    // }

  if(window.location.href.includes('viewHoldSample') && 
    (environment.Req_Server.includes(environment.externalUrl) || environment.Req_Server.includes('localhost'))){
    let qrCodeId = window.location.href.split("/").pop();
    localStorage.setItem('viewHoldSample', JSON.stringify(qrCodeId));
    console.log("viewHoldSample 72, QR Code ID:",qrCodeId );
    this.router.navigate(['/registerSample/registerSampleList']);                    
    console.log("viewHoldSample 74,:",localStorage.getItem('viewHoldSample'));
  }

    if(environment.isAuthRequired){
      if(environment.Req_Server.includes(environment.internalUrl)){
        this.internalUserAuth();
        this.titleServ.setTitle('COAT');
      }else if(environment.Req_Server.includes(environment.externalUrl)){
        this.externalUserAuth();
        //this.titleServ.setTitle('FluidWatch®');
        this.titleServ.setTitle('FluidWatch®');
      }else if(environment.Req_Server.includes('localhost')){
        this.sharedService.setUserInfo(this.getMockUserData());
        this.titleServ.setTitle('Localhost');
      }else{
        this.router.navigate(['/accessDenied']);
      }      
    }else{
      this.sharedService.setUserInfo(this.getMockUserData());
    }
  }

  getCompanyIdList() {
    var data = {
      companyId: this.sharedService.getCompanyId(),
      companyName: this.sharedService.getCompanyName()
    }
    
    this.analyzeSampleService.getCompanyList(data).subscribe(
      response => {
        if (response.statusCode == "1") {
          let companyIdList:any = response.companyIdList;
          localStorage.setItem('companyIdList', JSON.stringify(companyIdList));
        }
      }
    );
  }
  ViewReport(){
    if(window.location.href.includes('emailSampleReport') && 
      (environment.Req_Server.includes(environment.externalUrl) || environment.Req_Server.includes('localhost'))){
      let sampleId = window.location.href.split("/").pop();
      localStorage.setItem('emailSampleReport', sampleId);
      console.log("emailSampleReport 72, sampleID:",sampleId );
      this.navigateToViewReport(localStorage.getItem('emailSampleReport'));
      console.log("emailSampleReport 74,:",localStorage.getItem('emailSampleReport'));
    }
 }

  navigateToViewReport(sampleId) {
    this.companyIdList = JSON.parse(localStorage.getItem('companyIdList'));
    console.log("loader start"+JSON.parse(localStorage.getItem('companyIdList')));
    this.userRole = this.sharedService.getUserRole();
    var data = {
      columnName: 'sampleId',
      companyIdList: this.companyIdList,
      currentPage: '1',
      pageSize: '10',
      sampleId: sampleId,
      sortingOrder: 'desc',
      status: 'Published',
 
      //userRole: USERS.EXTERNAL,
      userRole: this.sharedService.getUserRole(),   
      //fluidType: 'Engine Oil (Liquid Fuel)'
    }
    
    this.analyzeSampleService.getAnalyzeSampleDashboardData(data).subscribe(
      response => {
        this.loader.show();
        if (response.statusCode == "1") {
          let sampleData = response.analyseSamplesList;
          if (sampleData.length > 0) {
            this.sharedService.setFluidType(sampleData[0].analysisType);
            localStorage.setItem('viewReportData', JSON.stringify(sampleData[0]));
            localStorage.removeItem('emailSampleReport');
            // let sampleId = window.location.href.split("/").pop();
            // this.router.navigate(['/emailSampleReport', { sampleId: sampleId }]);
            this.router.navigate(['/viewReport']);                    
          }
        }else{
          localStorage.removeItem('emailSampleReport');
          this.alert.error('Invalid sample Id');
        }
        this.loader.hide();
      }
    );
  }

  

  internalUserAuth(){   
    this.azureAuth();   
  }

  azureAuth(){
    if (this.cookieService.get('coat_auth') == "") { 
      if (window.location.hash != "") {       
        let hashflag = window.location.hash.substr(1, window.location.hash.length).split('&');
        hashflag.forEach(element => {
          if (element.indexOf('id_token') == 0) {
            this.id_token = element.substr(9, element.length);
            this.dataService.setAuthorization(this.id_token);            
            this.cookieService.set('coat_auth', this.id_token, 1 / 25, '/',null,true); 
            this.setUserInformation();           
            window.location.hash = "";            
          }
        });        
      }
      else {
        window.location.href = environment.internalAppUrl;
      }
    }else{
      this.dataService.setAuthorization(this.cookieService.get('coat_auth'));
      this.setUserInformation();
    }
  }

  setUserInformation(){    
    this.sharedService.getUserDetails({}).subscribe((data) =>{      
      if(data.statusCode == '1'){
        //console.log(data.userRole);
        this.sharedService.setUserInfo(data.userRole);
      }else{
        this.router.navigate(['/accessDenied']);
      }            
    },(error)=>{           
      localStorage.clear();
      this.cookieService.deleteAll();
      this.router.navigate(['/accessDenied']);     
    });   
  }

  getMockUserData(){
    var data = {email: "mock.user@cummins.com",
      endDate: null,
      firstName: "Mock",
      internalUser: true,
      isActive: null,
      lastName: "Data Scientist User",
      role: "2",
      roleName: USERTYPE.DATA_SCIENTIST,
      startDate: null,
      updatedBy: null,
      wwid: "mock1"
    }
      return data;
  }

  externalUserAuth(){    
    this.salesforceAuth();  
    this.sharedService.getUserInfo().subscribe((userInfo)=>{      
      if(userInfo.hasOwnProperty('roleName')){
        let coatIAMObservable = this.sharedService.getIAMAuthToken()
          .pipe(mergeMap(authRes => this.sharedService.getExternalUser(userInfo, authRes.access_token)));
        
        coatIAMObservable.subscribe((extData) =>{
          if(extData.done && extData.totalSize > 0){
            console.log(extData.records[this.index].IAM_Account_Application__r.IAM_Account_Name__r.IAM_Service_Provider_Code__c +""+extData.records[this.index].IAM_Account_Application__r.IAM_Account_Name__r.Name);
            for(let i =0; i<extData.totalSize; i++){
              if(extData.records[i].IAM_Role__c === 'Sample Fluid Registration User'){
                this.loginFlag = true;
                this.index = i;
                break;
              }
             
            }
            if(this.loginFlag){
            this.sharedService.setExternalUserInfo(extData.records[this.index]);      
            this.sharedService.setCompanyId(extData.records[this.index].IAM_Account_Application__r.IAM_Account_Name__r.IAM_Service_Provider_Code__c);
            this.sharedService.setCompanyName(extData.records[this.index].IAM_Account_Application__r.IAM_Account_Name__r.Name);
            this.getCompanyIdList();
            this.ViewReport();             
            }else{
              console.log("invalid");
                this.sharedService.setErrorMessage('Error Occured while logging in. Invalid Company code.' +" Company Id: "+ extData.records[this.index].IAM_Account_Application__r.IAM_Account_Name__r.IAM_Service_Provider_Code__c +" ,Company Name: "+ 
                extData.records[this.index].IAM_Account_Application__r.IAM_Account_Name__r.Name);
                this.updateLogs(environment.coatIAMUrl, null, null, null, null, null, '', ' Error Occured while logging in. Invalid Company code.' +" Company Id: "+ extData.records[this.index].IAM_Account_Application__r.IAM_Account_Name__r.IAM_Service_Provider_Code__c +" ,Company Name: "+ 
                extData.records[this.index].IAM_Account_Application__r.IAM_Account_Name__r.Name,true); 
                this.router.navigate(['/externalUserLogin']);
              }
                                
          }else{
            console.log("data not exists")
            this.sharedService.setErrorMessage('Error Occurred while logging in. FluidWatch data does not exists.');
            this.updateLogs(environment.coatIAMUrl, null, null, null, null, null, '', 'Error Occurred while logging in. FluidWatch data does not exists',true); 
            this.router.navigate(['/externalUserLogin']);
          }   
        },(error)=>{
          console.log("token expired")
          console.log("err msg" + error.message);
          console.log("err msg" + error.status);
          this.sharedService.setErrorMessage('Error Occurred while logging in.'+":"+error);
          this.updateLogs(environment.coatIAMUrl, null, null, null, null, null, '', 'Error Occurred while logging in.'+":"+error,true); 
          this.router.navigate(['/externalUserLogin']);
        });
      }
    });  
  }

  updateLogs(url, sampleId, orderId, qrCodeId, sku, flag, checkedList, resp, errorflag){
    let req = {
      "Header": {
        "Sender": {
          "ServiceName": "updateSampleIdService",
          "SenderID": "COAT"
        },
        "Target": {
          "TargetID": "WINLIMS"
        }
      },
      "DataArea": {
       "SampleId": sampleId,
       "OrderId": orderId,
       "SkuNumber": sku,
       "Flag": flag,
       "QrCode": qrCodeId,
       "RerunParameter": checkedList
       
      }
    };
    let reqLog= {
    
      "requestUrl": url,
      "requestPayload": JSON.stringify(req), 
      "responseGenerated": JSON.stringify(resp),
      "errorflag":errorflag
    
      }

      this.analyzeSampleInfoServ.middlewareLogs(reqLog).subscribe(response => {
        
      });
  }
  salesforceAuth(){
    if (this.cookieService.get('coat_auth') == "") { 
      if (window.location.hash != "") {       
        let hashflag = window.location.hash.substr(1, window.location.hash.length).split('&');
        hashflag.forEach(element => {
          if (element.indexOf('id_token') == 0) {
            this.id_token = element.substr(9, element.length);
            this.dataService.setAuthorization(this.id_token);            
            this.cookieService.set('coat_auth', this.id_token, 1 / 25, '/',null,true); 
            this.setUserInformation();           
            window.location.hash = "";            
          }
        });        
      }
      else {  
        if(window.location.href.includes('externalLogin') || window.location.href.includes('emailSampleReport') ||  window.location.href.includes('viewHoldSample')){
          this.router.navigate(['/externalLogin']);
        }else{
          this.router.navigate(['/externalUserLogin']);
        } 
      }
    }else{
      this.dataService.setAuthorization(this.cookieService.get('coat_auth'));
      this.setUserInformation();
    }
  }  

  getLoginStatus() {
    var status = 'Y';
    if (status == 'Y') {
      this.loginStatus = true;
      return true;
    }
  }
}
