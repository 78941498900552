import { LoaderService } from '../preloader/loader.service';
import { AlertService } from '../alert/alert.service';
import { OnInit, Component } from '@angular/core';
import { SharedService } from '../../service/shared.service';
import { USERTYPE } from 'src/app/View/common/constants/constant';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AnalyzeSampleGridService } from '../../core/analyze-sample/analyze-sample-grid/analyze-sample-grid.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  userRole: string = '';
  readonly USERTYPE = USERTYPE;
  
  constructor(private loader: LoaderService, private alert: AlertService,
    private sharedService: SharedService,private router:Router,
    private analyzeSampleService: AnalyzeSampleGridService) {}
  openModal: any = false;
  cancelModal: any = false;
  panelOpenState: any = false;
  homeImage="assets/img/homeImage.jpg";
  companyIdList = [];
  ngOnInit() {  
    this.loader.show(); 
    this.companyIdList = JSON.parse(localStorage.getItem('companyIdList'));  
    this.sharedService.getUserInfo().subscribe((userInfo)=>{       
      if(userInfo.hasOwnProperty('roleName')){        
        this.loader.hide();        
        this.userRole = userInfo.roleName;
      }  
    });
    
    if(localStorage.getItem('emailSampleReport') != null){
      console.log("emailSampleReport 38 :",localStorage.getItem('emailSampleReport'));
      this.navigateToViewReport(localStorage.getItem('emailSampleReport'));
    }
    if(localStorage.getItem('viewHoldSample') != null && this.companyIdList!=null){
      console.log("viewHoldSample 38 :",localStorage.getItem('viewHoldSample'));
      this.router.navigate(['/registerSample/registerSampleList']);                    
    }
  }
  purchaseSample(){
    let link=environment.purchaseSample;
    window.open(link,'_blank');
  }

  navigateToViewReport(sampleId) {
    this.userRole = this.sharedService.getUserRole();
    var data = {
      columnName: 'sampleId',
      companyId: this.sharedService.getCompanyId(),
      currentPage: '1',
      pageSize: '10',
      sampleId: sampleId,
      sortingOrder: 'desc',
      status: 'Published',
 
      //userRole: USERS.EXTERNAL,
      userRole: this.sharedService.getUserRole(),   
      //fluidType: 'Engine Oil (Liquid Fuel)'
    }
    
    this.analyzeSampleService.getAnalyzeSampleDashboardData(data).subscribe(
      response => {
        if (response.statusCode == "1") {
          let sampleData = response.analyseSamplesList;
          if (sampleData.length > 0) {
            this.sharedService.setFluidType(sampleData[0].analysisType);
            localStorage.setItem('viewReportData', JSON.stringify(sampleData[0]));
            localStorage.removeItem('emailSampleReport');
            this.router.navigate(['/viewReport']);                    
          }
        }else{
          localStorage.removeItem('emailSampleReport');
          this.alert.error('Invalid sample Id');
        }
      }
    );
  }
}
