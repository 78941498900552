import { AfterViewChecked, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { AddVehicleService } from '../add-vehicle/add-vehicle.service';
import { ManageFleetService } from '../manage-fleet/manage-fleet.service';
import { AlertService } from '../../common/alert/alert.service';
import { SharedService } from '../../service/shared.service';
import { USERTYPE } from 'src/app/View/common/constants/constant';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, forkJoin, Observable } from 'rxjs';
import { Console } from 'console';

@Component({
  selector: 'app-add-edit-vehicle',
  templateUrl: './add-edit-vehicle.component.html',
  styleUrls: ['./add-edit-vehicle.component.css']
})
export class AddEditVehicleComponent implements OnInit, AfterViewChecked {
  readonly USERTYPE = USERTYPE;
  fluidType = { 'Oil': '1', 'NatGas': '2', 'Coolant': '3', 'Fuel': '4', 'Def': '5' };
  selectedEngineMake: null;

  userRole: string = '';
  engineMake: any;
  engineModel: any;
  distanceUOMList = [];
  unitMakeList = [];
  engineMakeList = [];
  engineModelList = [];
  applicationTypeList = [];
  unitYearList = [];
  engMakeModelList = [];
  engineYearList = [];
  gsnMadatory: boolean = false;
  esnMadatory: boolean = false;
  engineModelField: boolean = false;
  engineMakeField: boolean = false;
  engineTypeList = [];
  companyIdList = [];
  componentTypeList = [];
  companyNameList: any;
  companyNameIAM: any;
  companyIdIAM: any;
  companyArrayIAM = new Array();
  statusMessage: '';
  successModal: boolean = false;
  mode = '';
  dataArray = new Array();
  unitMakeArray = new Array();
  engineYearListArray = new Array();
  unitYearListArray = new Array();
  engineModelListArray = new Array();
  engineMakeListArray = new Array();
  // engineTypeListArray = new Array();
  applicationTypeListArray = new Array();
  distanceUOMListArray = new Array();
  selectedLevel;
  // for OIL 
  fluidBrandForOilList = [];
  fluidBrandForOilListArr = [];
  fluidProductForOilList = [];
  fluidProductForOilListArr = [];
  fluidGradeForOilList = [];
  filterBrandForOilList = [];
  filterProductForOilList = [];
  fluidCombinationForOilList = [];
  filterCombinationForOilList = [];
  oilFluidBrand: any;
  oilFluidProduct: any;
  oilfilterProduct: any;
  filterProductForOilListArr = [];

  //for Coolant
  fluidBrandForCoolantList = [];
  fluidBrandForCoolantListArr = [];
  fluidProductForCoolantList = [];
  fluidProductForCoolantListArr = [];
  fluidGradeForCoolantList = [];
  filterBrandForCoolantList = [];
  filterProductForCoolantList = [];
  fluidCombinationForCoolantList = [];
  filterCombinationForCoolantList = [];
  coolantFluidBrand: any;
  coolantFluidProduct: any;
  coolantFilterProduct: any;
  filterProductForCoolantListArr = [];

  //for Fuel
  fluidBrandForFuelList = [];
  fluidProductForFuelList = [];
  fluidGradeForFuelList = [];
  filterBrandForFuelList = [];
  filterProductForFuelList = [];
  fluidCombinationForFuelList = [];
  filterCombinationForFuelList = [];
  fuelFilterProduct: any;
  filterProductForFuelListArr = [];

  //for DEF
  fluidBrandForDefList = [];
  fluidBrandForDefListArr = [];
  fluidProductForDefList = [];
  fluidProductForDefListArr = [];
  fluidGradeForDefList = [];
  filterBrandForDefList = [];
  filterProductForDefList = [];
  fluidCombinationForDefList = [];
  filterCombinationForDefList = [];
  defFluidBrand: any;
  defFluidProduct: any;

  //initial data
  initialData: any;
  individualData: any;
  dropdownOil: any;
  dropdownCoolant: any;
  dropdownFuel: any;
  dropdownDef: any;
  // isReviewUnit= false;
  scrollToTop = false;
  companyId: any;
  isBulkTankFuelStorage = false;
  selectedObject: any;
  engineMakeHasError = false;
  applicationTypeHasError = false;
  engineModelHasError = false;
  unitMakeHasError = false;
  engineModelHasListedError = false;
  engineMakeHasListedError = false;
  unitMakeHasListedError = false;
  applicationTypeHasListedError = false;

  isFormValid = false;
  isFirstTime = true
  dbuCustomerNameDropdown: any;
  messageDropdown: any;

  @Output() options = new EventEmitter<{
    privacy: string;
  }>();
  optIn: {
    privacy: boolean;
  } = {
      privacy: true,

    };
  dropdownName: any;
  optionName: any;
  primaryUnitIdAsGSN: any;
  newUnitMakeValue: any;
  newApplicationTypeValue: any;
  newEngineMakeValue: any;
  newEngineModelValue: any;
  engineModelHasUnknownError = false;
  dropdownForEngineList: any;
  extacompany: any;
  isdbuRoleConfirmation: boolean = false;
  compnayNameFromDropdown: any;
  companyIdFromDropdown: any;
  companyCityFromDropdown: any;
  companyStateFrmDropdown: any;
  companyPinFromDropdown: any;
  isAddEquipButtonDisable: boolean = false;
  behaviorObs: any; 
  selectedComponentsList:any=[];
 componentAbbrName = '';
  selectedTab: String = '';
  // componentInfoList: any = [
  //   {name: "Component 1", value: "Engine"}
  // ];
  
  // componentForms: { label: string, selectedComponent: string }[] = [{ label: 'Component 1', selectedComponent: '' }];
  editEquipForm: FormGroup;
  enableEngineTab: boolean;  
  enableOtherTab: boolean;
  addComponentForm = false;
  // componentForm: FormGroup;
  // componentUnit: FormArray;
  
  saveComponentArr = [
    { code: 'componentId', name: 'Component Id' },
    { code: 'component', name: 'Component' },
    { code: 'Secondary Id', name: 'Secondary Id' },
    { code: 'componentMake', name: 'Component Make' },
    { code: 'componentModel', name: 'Component Model' },
    { code: 'componentYear', name: 'Component Year' },
    { code: 'SerialNo', name: 'Serial No' },
    { code: 'Action', name: 'Action' }
  ];
  dataArr1: any = [];
  newArr: any = [];
  gridValues:any = [];
  showGrid: boolean;
  enableSave = false;
  componentIdNew = '';
  enableAddComponents = false;
  deleteComponentRecord = false;
  componentIndex = '';
  editComponentIndex = '';
  editComponentClicked = false;
  lastComponent;
  componentEditResponse;
  selectionComponentIndexValue;
  companyName: any;
  unitResponse: any;
  constructor(private route: ActivatedRoute, private addEquipServ: AddVehicleService,
    private manageFleetService: ManageFleetService, private alertService: AlertService,
    private sharedService: SharedService, private fb: UntypedFormBuilder, private formBuilder: FormBuilder, private router: Router) { 

    this.editEquipForm = new FormGroup({
      bulkTankFuelStorage: new UntypedFormControl(''),
      userId: new UntypedFormControl(''),
      isReviewUnit: new UntypedFormControl(
        this.sharedService.getAddOrEdit().mode == 'add' ? false : this.sharedService.getInformation().reviewUnit == 'Y' ? true : false,
      ),
      selectedComponent: new UntypedFormControl(''),
      equipmentId: new UntypedFormControl(''),
      unitId: new UntypedFormControl('', [Validators.required]),
      equipSerialNo: new UntypedFormControl('', [Validators.required]),
      secondaryUnitId: new UntypedFormControl('', [Validators.required]),
      companyId: new UntypedFormControl(''),
      extcompanyID: new UntypedFormControl(''),
      companyName: new UntypedFormControl(''),
      esn: new UntypedFormControl(''),
      gsn: new UntypedFormControl(''),
      distanceUom: new UntypedFormControl('', [Validators.required]),
      unitMake: new UntypedFormControl('', [Validators.required]),
      unitModel: new UntypedFormControl('', [Validators.required]),
      components: new UntypedFormControl(["Engine"], [Validators.required]),      
      applicationType: new UntypedFormControl('', [Validators.required]),
      unitYear: new UntypedFormControl(''),
      engineMake: new UntypedFormControl(''),      
      componentMake: new UntypedFormControl(''),
      engineMakeName: new UntypedFormControl(''),
      applicationTypeName: new UntypedFormControl(''),
      unitMakeName: new UntypedFormControl(''),
      engineModel: new UntypedFormControl(''),
      componentModel: new UntypedFormControl(''),
      engineModelName: new UntypedFormControl(''),
      serialNo: new UntypedFormControl(''),
      engineYear: new UntypedFormControl(''),
      componentYear: new UntypedFormControl(''),
      unitMeasure: new UntypedFormControl(''),
      engineType: new UntypedFormControl('', [Validators.required]),
      fluidTypeForOil: new UntypedFormControl(''),
      fluidBrandForOil: new UntypedFormControl(''),
      fluidProductForOil: new UntypedFormControl(''),
      fluidGradeForOil: new UntypedFormControl(''),
      filterBrandForOil: new UntypedFormControl(''),
      filterProductForOil: new UntypedFormControl(''),

      fluidTypeForCoolant: new UntypedFormControl(''),
      fluidBrandForCoolant: new UntypedFormControl(''),
      fluidProductForCoolant: new UntypedFormControl(''),
      fluidGradeForCoolant: new UntypedFormControl(''),
      filterBrandForCoolant: new UntypedFormControl(''),
      filterProductForCoolant: new UntypedFormControl(''),

      fluidTypeForFuel: new UntypedFormControl(''),
      fluidBrandForFuel: new UntypedFormControl(''),
      fluidProductForFuel: new UntypedFormControl(''),
      fluidGradeForFuel: new UntypedFormControl(''),
      filterBrandForFuel: new UntypedFormControl(''),
      filterProductForFuel: new UntypedFormControl(''),

      fluidTypeForDef: new UntypedFormControl(''),
      fluidBrandForDef: new UntypedFormControl(''),
      fluidProductForDef: new UntypedFormControl(''),
      fluidGradeForDef: new UntypedFormControl(''),
      createdBy: new UntypedFormControl(''),
      createdDate: new UntypedFormControl(''),
      isChecked: new UntypedFormControl({ value: '', disabled: true }),
      lastSampleDateForOil: new UntypedFormControl({ value: '', disabled: true }),
      unitDistanceForOil: new UntypedFormControl({ value: '', disabled: true }),
      fluidDistanceForOil: new UntypedFormControl({ value: '', disabled: true }),
      sampledLocationForOil: new UntypedFormControl({ value: '', disabled: true }),
      isFluidChangedForOil: new UntypedFormControl({ value: '', disabled: true }),
      isFluidTopOffForOil: new UntypedFormControl({ value: '', disabled: true }),
      topOffAmtForOil: new UntypedFormControl({ value: '', disabled: true }),
      isSCAAddedForOil: new UntypedFormControl({ value: '', disabled: true }),
      bioFuelForOil: new UntypedFormControl({ value: '', disabled: true }),

      lastSampleDateForCoolant: new UntypedFormControl({ value: '', disabled: true }),
      unitDistanceForCoolant: new UntypedFormControl({ value: '', disabled: true }),
      fluidDistanceForCoolant: new UntypedFormControl({ value: '', disabled: true }),
      sampledLocationForCoolant: new UntypedFormControl({ value: '', disabled: true }),
      isFluidChangedForCoolant: new UntypedFormControl({ value: '', disabled: true }),
      isFluidTopOffForCoolant: new UntypedFormControl({ value: '', disabled: true }),
      topOffAmtForCoolant: new UntypedFormControl({ value: '', disabled: true }),
      isSCAAddedForCoolant: new UntypedFormControl({ value: '', disabled: true }),
      bioFuelForCoolant: new UntypedFormControl({ value: '', disabled: true }),

      lastSampleDateForFuel: new UntypedFormControl({ value: '', disabled: true }),
      unitDistanceForFuel: new UntypedFormControl({ value: '', disabled: true }),
      fluidDistanceForFuel: new UntypedFormControl({ value: '', disabled: true }),
      sampledLocationForFuel: new UntypedFormControl({ value: '', disabled: true }),
      isFluidChangedForFuel: new UntypedFormControl({ value: '', disabled: true }),
      isFluidTopOffForFuel: new UntypedFormControl({ value: '', disabled: true }),
      topOffAmtForFuel: new UntypedFormControl({ value: '', disabled: true }),
      isSCAAddedForFuel: new UntypedFormControl({ value: '', disabled: true }),
      bioFuelForFuel: new UntypedFormControl({ value: '', disabled: true }),

      lastSampleDateForDEF: new UntypedFormControl({ value: '', disabled: true }),
      unitDistanceForDEF: new UntypedFormControl({ value: '', disabled: true }),
      fluidDistanceForDEF: new UntypedFormControl({ value: '', disabled: true }),
      sampledLocationForDEF: new UntypedFormControl({ value: '', disabled: true }),
      isFluidChangedForDEF: new UntypedFormControl({ value: '', disabled: true }),
      isFluidTopOffForDEF: new UntypedFormControl({ value: '', disabled: true }),
      topOffAmtForDEF: new UntypedFormControl({ value: '', disabled: true }),
      isSCAAddedForDEF: new UntypedFormControl({ value: '', disabled: true }),
      bioFuelForDEF: new UntypedFormControl({ value: '', disabled: true }),

      isOilChecked: new UntypedFormControl(''),
      isCoolantChecked: new UntypedFormControl(''),
      isFuelChecked: new UntypedFormControl(''),
      isDefChecked: new UntypedFormControl(''),

      unitMakeNotListed: new UntypedFormControl(
        this.sharedService.getAddOrEdit().mode == 'add' ? false : this.sharedService.getInformation().unitMakeFlag == 'N' ? true : false,
      ),

      engineMakeNotListed: new UntypedFormControl(
        this.sharedService.getAddOrEdit().mode == 'add' ? false : this.sharedService.getInformation().engineMakeFlag == 'N' ? true : false,
      ),
      gsnNotListed: new UntypedFormControl(''),
      engineModelNotListed: new UntypedFormControl(
        this.sharedService.getAddOrEdit().mode == 'add' ? false : this.sharedService.getInformation().engineModFlag == 'N' ? true : false,

      ),
      applicationTypeNotListed: new UntypedFormControl(
        this.sharedService.getAddOrEdit().mode == 'add' ? false : this.sharedService.getInformation().appTypeFlag == 'N' ? true : false,
      ),
      // useAsPrimaryUnit: new FormControl(''),
      fluidBrandForOilNotListed: new UntypedFormControl(''),
      fluidProductForOilNotListed: new UntypedFormControl(''),
      fluidGradeForOilNotListed: new UntypedFormControl(''),
      filterBrandForOilNotListed: new UntypedFormControl(''),
      filterProductForOilNotListed: new UntypedFormControl(''),

      fluidBrandForCoolantNotListed: new UntypedFormControl(''),
      fluidProductForCoolantNotListed: new UntypedFormControl(''),
      fluidGradeForCoolantNotListed: new UntypedFormControl(''),
      filterBrandForCoolantNotListed: new UntypedFormControl(''),
      filterProductForCoolantNotListed: new UntypedFormControl(''),

      fluidBrandForFuelNotListed: new UntypedFormControl(''),
      fluidProductForFuelNotListed: new UntypedFormControl(''),
      fluidGradeForFuelNotListed: new UntypedFormControl(''),
      filterBrandForFuelNotListed: new UntypedFormControl(''),
      filterProductForFuelNotListed: new UntypedFormControl(''),

      fluidBrandForDefNotListed: new UntypedFormControl(''),
      fluidProductForDefNotListed: new UntypedFormControl(''),
      fluidGradeForDefNotListed: new UntypedFormControl('')

    });
  }


  ngOnInit() {
    if (this.sharedService.getAddOrEdit().mode == 'edit') {
      this.primaryUnitIdAsGSN = this.sharedService.getInformation().unitId;
      this.newUnitMakeValue = this.sharedService.getInformation().unitMake;
      this.newApplicationTypeValue = this.sharedService.getInformation().applicationType;
      this.newEngineMakeValue = this.sharedService.getInformation().engineMake;
      this.newEngineModelValue = this.sharedService.getInformation().engineModel;
    }


    // this.userRole = this.sharedService.getUserRole();
    // if(this.userRole === USERTYPE.LAB_ADMIN || this.userRole == USERTYPE.DATA_SCIENTIST){
    //   this.editEquipForm.controls['companyId'].setValidators([Validators.required]);
    //   this.route.paramMap.subscribe(params => {
    //     let companyIdFromSample = params.get("companyId");
    //     if(companyIdFromSample != ''){
    //       this.editEquipForm.patchValue({
    //         companyId : companyIdFromSample
    //       })
    //     }
    //   });
    // }else if(this.userRole === USERTYPE.EXTERNAL){
    //   this.editEquipForm.patchValue({
    //     companyId : this.sharedService.getCompanyId()
    //   })
    //   this.editEquipForm.controls['companyId'].clearValidators();
    // }
    // this.editEquipForm.controls['companyId'].updateValueAndValidity();
    // this.route.paramMap.subscribe(params => {
    //   this.mode = params.get("mode");
    //   if(this.mode == 'add'){
    //     this.initForAdd();
    //   }else if(this.mode == 'edit'){
    //     this.initForEdit();
    //   }
    // });      

    this.userRole = this.sharedService.getUserRole();
    if (this.sharedService.getAddOrEdit()) {
      this.mode = this.sharedService.getAddOrEdit().mode;
      this.companyId = this.sharedService.getAddOrEdit().companyId;

      // this.companyNameList = this.sharedService.getAddOrEdit().companyName;
    }
    if (this.userRole === USERTYPE.LAB_ADMIN || this.userRole == USERTYPE.DATA_SCIENTIST || this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS || this.userRole == USERTYPE.FLUIDWATCH_MANAGER) {
      this.editEquipForm.controls['companyId'].setValidators([Validators.required]);
      let companyIdFromSample = this.companyId;
      if (companyIdFromSample != '') {
        this.editEquipForm.patchValue({
          companyId: companyIdFromSample
        })
      }
    }
    // else if (this.userRole === USERTYPE.EXTERNAL) {
    //   this.editEquipForm.patchValue({
    //     companyId: this.sharedService.getCompanyId()
    //   })
    //   console.log(this.sharedService.getCompanyId());
    //   this.editEquipForm.controls['companyId'].clearValidators();
    // }
    this.editEquipForm.controls['companyId'].updateValueAndValidity();
    if (this.mode == 'add') {

      this.initForAdd();
    } else if (this.mode == 'edit') {
      this.initForEdit();
    }
    if (this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS) {
      this.isAddEquipButtonDisable = true;
      if (localStorage.getItem("branchCustomerDropdown")) {
        this.recieveMessage(JSON.parse(localStorage.getItem("branchCustomerDropdown")))
        // console.log('localstorage');
      }
    }
    this.behaviorObs = this.sharedService.currentChange.subscribe(message => {
      if (this.router.url == '/editVehicle') {
        this.messageDropdown = message;
        if (this.isFirstTime == false) {
          this.recieveMessage(this.messageDropdown);
          // console.log('sharedService')
        }
        this.isFirstTime = false;
      }
    });    
  }

  ngOnDestroy() {
    this.behaviorObs.unsubscribe();
  }

  recieveMessage(event) {
    if (this.sharedService.getAddOrEdit().mode == 'add') {
      this.dbuCustomerNameDropdown = event;
      if (this.dbuCustomerNameDropdown.customerId == 'notListed' || this.dbuCustomerNameDropdown.customerId == '') {
        this.editEquipForm.patchValue({
          companyId: ''
        })
        this.isAddEquipButtonDisable = true;
      } else {
        this.editEquipForm.patchValue({
          companyId: this.dbuCustomerNameDropdown.customerId
        })
        this.isAddEquipButtonDisable = false;
        this.compnayNameFromDropdown = this.dbuCustomerNameDropdown.customerName;
        this.companyIdFromDropdown = this.dbuCustomerNameDropdown.customerId;
        this.companyCityFromDropdown = this.dbuCustomerNameDropdown.branchName;
        this.companyStateFrmDropdown = this.dbuCustomerNameDropdown.customerState;
        this.companyPinFromDropdown = this.dbuCustomerNameDropdown.customerZipCode;
      }
    }
  }

  ngAfterViewChecked() {
    if (this.scrollToTop) {
      let element = document.querySelector('#alertDiv');
      if (element != null) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        element = null;
      }
      this.scrollToTop = false;
    }
  }

  initForAdd() {

    if (this.userRole == USERTYPE.EXTERNAL) {
      this.editEquipForm.controls['companyId'].disable();
      this.editEquipForm.patchValue({
        companyId: this.sharedService.getCompanyId(),
      });
    }
    if (this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS) {
      this.editEquipForm.controls['companyId'].disable()
    }
    this.editEquipForm.patchValue({
      userId: this.sharedService.getUserId(),
      isOilChecked: true,
      fluidTypeForOil: this.fluidType['Oil'],
      engineType: 1,
      engineModel: this.editEquipForm.controls['engineModel'].disable(),
      engineModelNotListed: this.editEquipForm.controls['engineModelNotListed'].disable(),
    });
    forkJoin([
      this.getEquipmentDropdownData(),
      // this.getEquipmentDropdownModelData(),
      this.getFluidAndFilterDetailsForOil(),
      this.getFluidAndFilterDetailsForCoolant(),
      this.getFluidAndFilterDetailsForFuel(),
      this.getFluidAndFilterDetailsForDef()
    ]).subscribe(allResults => {
      this.setEquipmentDropdownData(allResults[0]),
        this.setFluidAndFilterDetailsForOil(allResults[1]),
        this.setFluidAndFilterDetailsForCoolant(allResults[2]),
        this.setFluidAndFilterDetailsForFuel(allResults[3]),
        this.setFluidAndFilterDetailsForDef(allResults[4])
    });
    this.enableFluidOil();
    //this.disableFluidOil();
    this.disableFluidCoolant();
    this.disableFluidFuel();
    this.disableFluidDef();
    this.enableFilterOil();
    //this.disableFilterOil();
    this.disableFilterCoolant();
    this.disableFilterFuel();
  }

  initForEdit() {

    if (this.sharedService.getInformation().engineMake == '') {
      this.editEquipForm.patchValue({
        engineModel: this.editEquipForm.controls['engineModel'].disable(),
        engineModelNotListed: this.editEquipForm.controls['engineModelNotListed'].disable(),
      });
    }


    this.editEquipForm.controls['companyId'].disable();
    this.editEquipForm.controls['extcompanyID'].disable();
    this.editEquipForm.patchValue({
      // isReviewUnit:this.editEquipForm.controls["isReviewUnit"].value ? 'Y' : 'N',
      extcompanyID: this.sharedService.getInformation().companyId,
      equipmentId: this.sharedService.getInformation().equipmentId,
      secondaryUnitId: this.sharedService.getInformation().secondaryUnitId,
      // companyName: this.sharedService.getInformation.companyName,
      esn: this.sharedService.getInformation().esn,
      gsn: this.sharedService.getInformation().gsn,
    });
    if (this.editEquipForm.controls["unitMakeNotListed"].value == true) {
      this.editEquipForm.patchValue({
        unitMakeName: this.sharedService.getInformation().unitMake,
        unitMake: '',
      });
      this.editEquipForm.controls['unitMake'].disable();

    }

    if (this.editEquipForm.controls["applicationTypeNotListed"].value == true) {
      this.editEquipForm.patchValue({
        applicationTypeName: this.sharedService.getInformation().applicationType,
        applicationType: ''
      });
      this.editEquipForm.controls['applicationType'].disable();
    }

    if (this.editEquipForm.controls["engineMakeNotListed"].value == true) {
      this.editEquipForm.patchValue({
        engineMakeName: this.sharedService.getInformation().engineMake,
        engineMake: ''
      });
      this.editEquipForm.controls['engineMake'].disable();
    }

    if (this.editEquipForm.controls["engineModelNotListed"].value == true) {
      this.editEquipForm.patchValue({
        engineModelName: this.sharedService.getInformation().engineModel,
        engineModel: ''
      });
      this.editEquipForm.controls['engineModel'].disable();
    }

    //  console.log(this.engineMake, 'engineMake');
    forkJoin([
      this.getEquipmentDropdownData(),
      // this.getEquipmentDropdownModelData(),
      this.getFluidAndFilterDetailsForOil(),
      this.getFluidAndFilterDetailsForCoolant(),
      this.getFluidAndFilterDetailsForFuel(),
      this.getFluidAndFilterDetailsForDef(),
      this.getRecordInfoOnEdit()
    ]).subscribe(allResults => {
      this.setEquipmentDropdownData(allResults[0]),
        this.setFluidAndFilterDetailsForOil(allResults[1]),
        this.setFluidAndFilterDetailsForCoolant(allResults[2]),
        this.setFluidAndFilterDetailsForFuel(allResults[3]),
        this.setFluidAndFilterDetailsForDef(allResults[4]),
        this.setRecordInfoOnEdit(allResults[5])
    });
  }

  setEditValue(){ 
    const payloadForEdit = {
      "equipmentId": this.sharedService.getInformation().equipmentId,
      "unitNo": this.sharedService.getInformation().unitNo
    };
    this.manageFleetService.fetchEquipmentInfo(payloadForEdit).subscribe(
      response => {
        if (response.statusCode == "1") {
          this.enableSave = true;
          let componentResponse = response.components;
          let unitResponse = response.unit;
          localStorage.setItem("Externalusercompanyname", JSON.stringify(unitResponse));
          this.componentEditResponse = response.components;
          this.lastComponent = componentResponse[componentResponse.length - 1];
          if (this.userRole == USERTYPE.EXTERNAL) {
              this.editEquipForm.patchValue({
                companyId: response.unit.companyId
          });
           console.log(' companyId: response.unit.companyId', response.unit.companyId)
          }
          this.editEquipForm.patchValue({
            userId: this.sharedService.getUserId(),            
            bulkTankFuelStorage: (response.unit.bulkTankFuelStorage !== undefined)? response.unit.bulkTankFuelStorage: '',
            unitId: (response.unit.unitId !== undefined)? response.unit.unitId: '',
            equipSerialNo: (response.unit.esn !== undefined)? response.unit.esn: '',
            unitMake: (response.unit.unitMake !== undefined)? response.unit.unitMake: '',
            unitModel: (response.unit.unitModel !== undefined)? response.unit.unitModel: '',
            unitYear: (response.unit.unitYear !== undefined)? response.unit.unitYear: '',
            companyId: (response.unit.companyId !== undefined)? response.unit.companyId: '',        
            applicationType: (response.unit.applicationType !== undefined)? response.unit.applicationType: '',
            isReviewUnit: (unitResponse.isReviewUnit == 'Y'? true: false)
          //  selectedComponent: (this.lastComponent.componentType !== undefined)? this.lastComponent.componentType: ''



            // extcompanyID: this.sharedService.getInformation().companyId,
            // equipmentId: this.sharedService.getInformation().equipmentId,            
            // secondaryUnitId: this.sharedService.getInformation().secondaryUnitId,            
            // // companyName: this.sharedService.getInformation.companyName,
            // distanceUom: this.sharedService.getInformation().distanceUOM,  
            // engineMake: this.sharedService.getInformation().engineMake,
            // engineModel: this.sharedService.getInformation().engineModel,
            // engineYear: this.sharedService.getInformation().engineYear,
            // engineType: +this.sharedService.getInformation().engineType,      
          });        
        //  this.selectedTab = this.lastComponent.componentType;
          this.showGrid = true; //show added component table list
          this.enableAddComponents = true;
        
          if(this.lastComponent.componentType.toUpperCase() == 'ENGINE') {
            const engineType = this.engineTypeList.filter(x => x.engineTypeName == this.lastComponent.engineType);
            this.editEquipForm.patchValue({
              secondaryUnitId: this.lastComponent.secondaryUnitId,
              engineType: engineType[0].engineType,
              engineMake: this.lastComponent.componentMake,
              engineModel: this.lastComponent.componentModel,
              engineYear: this.lastComponent.componentYear,
              distanceUom: this.lastComponent.distanceUom,
              serialNo: this.lastComponent.csn
            });
          } else {
            this.editEquipForm.patchValue({
              secondaryUnitId: this.lastComponent.secondaryUnitId,
              engineType: '1',
              componentMake: this.lastComponent.componentMake,
              componentModel: this.lastComponent.componentModel,
              componentYear: this.lastComponent.componentYear,
              distanceUom: this.lastComponent.distanceUom,
              serialNo: this.lastComponent.csn
            });
          }


        componentResponse.forEach((val, i) => { 
          // let newAbbrNo;
          // const abbrNumber = this.newArr.filter(row => {
          //   return (row.componentName == val.componentType);
          // });  
          // if(!this.editComponentClicked){
          //   newAbbrNo = Number(abbrNumber.length + 1); 
          // } else {
          //   newAbbrNo = this.newArr[this.editComponentIndex].componentIndex;
          // }
          const dataArr1 = {
            'Make': val.componentMake,
            'Model': val.componentModel,
            'Year': val.componentYear,
            'componentName':  val.componentType,
            'componentId':val.componentId,
            'unitMeasure': val.distanceUom,              
            'serialNo': val.csn,
            'secondaryId': val.secondaryUnitId,

            'fluidTypeForOil': (val.engineType == "Diesel Engine")? '1' : '2',
            'fluidBrandForOil': this.initialData[i].fluidForOil.fluidBrand,
            'fluidProductForOil': this.initialData[i].fluidForOil.fluidProduct,
            'fluidGradeForOil': this.initialData[i].fluidForOil.fluidGrade,
            'filterBrandForOil': this.initialData[i].filterForOil.filterBrand,
            'filterProductForOil': this.initialData[i].filterForOil.filterProduct,
            
            
            'fluidTypeForCoolant': (this.initialData[i].fluidForCoolant
              .equipId !== '')? '3' : '',
            'fluidBrandForCoolant': this.initialData[i].fluidForCoolant.fluidBrand,
            'fluidProductForCoolant': this.initialData[i].fluidForCoolant.fluidProduct,
            'fluidGradeForCoolant': this.initialData[i].fluidForCoolant.fluidGrade,
            'filterBrandForCoolant': this.initialData[i].filterForCoolant.filterBrand,
            'filterProductForCoolant': this.initialData[i].filterForCoolant.filterProduct,

            'fluidTypeForFuel': (this.initialData[i].fluidForFuel
              .equipId !== '')? "4" : '',   
            'fluidBrandForFuel': this.initialData[i].fluidForFuel.fluidBrand,
            'fluidProductForFuel': this.initialData[i].fluidForFuel.fluidProduct,
            'fluidGradeForFuel': this.initialData[i].fluidForFuel.fluidGrade,
            'filterBrandForFuel': this.initialData[i].filterForFuel.filterBrand,
            'filterProductForFuel': this.initialData[i].filterForFuel.filterProduct,

            
            'fluidTypeForDef': (this.initialData[i].fluidForDEF
              .equipId !== '')? "5" : '',
            'fluidBrandForDef': this.initialData[i].fluidForDEF.fluidBrand,
            'fluidProductForDef': this.initialData[i].fluidForDEF.fluidProduct,
            'fluidGradeForDef': this.initialData[i].fluidForDEF.fluidGrade,

          //  'componentIndex': newAbbrNo,
            'isDefChecked': this.editEquipForm.controls['isDefChecked'].value == true? true: false,
            'isOilChecked': this.editEquipForm.controls['isOilChecked'].value == true? true: false,
            'isCoolantChecked': this.editEquipForm.controls['isCoolantChecked'].value == true? true:  false,
            'isFuelChecked': this.editEquipForm.controls['isFuelChecked'].value == true? true: false,

            'engineMakeNotListed': this.editEquipForm.controls['engineMakeNotListed'].value == true? true:false,
            'engineModelNotListed': this.editEquipForm.controls['engineModelNotListed'].value == true? true:false,
            'equipmentId': val.equipmentId
          }
          this.newArr.push(dataArr1); 
          console.log('this.newArr',this.newArr)
        });       

          
        } else {
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      }
    );

  }

  getRecordInfoOnEdit(): Observable<any> {
    let data = {
      "equipmentId": this.sharedService.getInformation().equipmentId,
      "unitNo": this.sharedService.getInformation().unitNo
    }
    return this.manageFleetService.getShowMoreInfo(data);
  }

  setRecordInfoOnEdit(response, index = 0) {
    this.initialData = (response.fluidAndfilterDetails !== undefined)? response.fluidAndfilterDetails : response;
    this.individualData = this.initialData[index];
    console.log("setRecordInfoOnEdit this.individualData  : ", this.individualData );

    if(this.individualData !== undefined) {
      this.editEquipForm.patchValue({
        /*fluidBrandForOil: response.fluidForOil.fluidBrand,
        fluidProductForOil: response.fluidForOil.fluidProduct,
        fluidGradeForOil: response.fluidForOil.fluidGrade,
        filterBrandForOil: response.filterForOil.filterBrand,
        filterProductForOil: response.filterForOil.filterProduct,

        fluidBrandForCoolant: response.fluidForCoolant.fluidBrand,
        fluidProductForCoolant: response.fluidForCoolant.fluidProduct,
        fluidGradeForCoolant: response.fluidForCoolant.fluidGrade,

        fluidBrandForFuel: response.fluidForFuel.fluidBrand,
        fluidProductForFuel: response.fluidForFuel.fluidProduct,
        fluidGradeForFuel: response.fluidForFuel.fluidGrade,
        filterBrandForFuel: response.filterForFuel.filterBrand,
        filterProductForFuel: response.filterForFuel.filterProduct,

        fluidBrandForDef: response.fluidForDEF.fluidBrand,
        fluidProductForDef: response.fluidForDEF.fluidProduct,
        fluidGradeForDef: response.fluidForDEF.fluidGrade,*/

        lastSampleDateForOil: this.individualData.oilSampleInfo.lastSampledDate,
        unitDistanceForOil: this.individualData.oilSampleInfo.unitDistance,
        fluidDistanceForOil: this.individualData.oilSampleInfo.fluidDistance,
        sampledLocationForOil: this.individualData.oilSampleInfo.sampledLocation,
        isFluidChangedForOil: this.individualData.oilSampleInfo.isFluidChanged,
        isFluidTopOffForOil: this.individualData.oilSampleInfo.isFluidTopOff,
        topOffAmtForOil: this.individualData.oilSampleInfo.topOffAmt,
        isSCAAddedForOil: this.individualData.oilSampleInfo.isSCAAdded,
        bioFuelForOil: this.individualData.oilSampleInfo.biofuel,

        lastSampleDateForCoolant: this.individualData.coolantSampleInfo.lastSampledDate,
        unitDistanceForCoolant: this.individualData.coolantSampleInfo.unitDistance,
        fluidDistanceForCoolant: this.individualData.coolantSampleInfo.fluidDistance,
        sampledLocationForCoolant: this.individualData.coolantSampleInfo.sampledLocation,
        isFluidChangedForCoolant: this.individualData.coolantSampleInfo.isFluidChanged,
        isFluidTopOffForCoolant: this.individualData.coolantSampleInfo.isFluidTopOff,
        topOffAmtForCoolant: this.individualData.coolantSampleInfo.topOffAmt,
        isSCAAddedForCoolant: this.individualData.coolantSampleInfo.isSCAAdded,
        bioFuelForCoolant: this.individualData.coolantSampleInfo.biofuel,

        lastSampleDateForFuel: this.individualData.fuelSampleInfo.lastSampledDate,
        unitDistanceForFuel: this.individualData.fuelSampleInfo.unitDistance,
        fluidDistanceForFuel: this.individualData.fuelSampleInfo.fluidDistance,
        sampledLocationForFuel: this.individualData.fuelSampleInfo.sampledLocation,
        isFluidChangedForFuel: this.individualData.fuelSampleInfo.isFluidChanged,
        isFluidTopOffForFuel: this.individualData.fuelSampleInfo.isFluidTopOff,
        topOffAmtForFuel: this.individualData.fuelSampleInfo.topOffAmt,
        isSCAAddedForFuel: this.individualData.fuelSampleInfo.isSCAAdded,
        bioFuelForFuel: this.individualData.fuelSampleInfo.biofuel,

        lastSampleDateForDEF: this.individualData.defSampleInfo.lastSampledDate,
        unitDistanceForDEF: this.individualData.defSampleInfo.unitDistance,
        fluidDistanceForDEF: this.individualData.defSampleInfo.fluidDistance,
        sampledLocationForDEF: this.individualData.defSampleInfo.sampledLocation,
        isFluidChangedForDEF: this.individualData.defSampleInfo.isFluidChanged,
        isFluidTopOffForDEF: this.individualData.defSampleInfo.isFluidTopOff,
        topOffAmtForDEF: this.individualData.defSampleInfo.topOffAmt,
        isSCAAddedForDEF: this.individualData.defSampleInfo.isSCAAdded,
        bioFuelForDEF: this.individualData.defSampleInfo.biofuel,

        createdBy: this.individualData.fluidForOil.createdBy,
        createdDate: this.individualData.fluidForOil.createdDate
      });
    }

    this.setValueFluidOil();
    this.setValueFluidCoolant();
    this.setValueFluidFuel();
    this.setValueFluidDef();
    this.setValueFilterOil();
    this.setValueFilterCoolant();
    this.setValueFilterFuel();
  }



  updateVehicle() {
    let unitMakeNewName;
    let applicationTypeNewName;
    let engineMakeNewName;
    let engineModelNewName;

    if (this.editEquipForm.controls["unitMakeNotListed"].value == true && this.newUnitMakeValue != this.editEquipForm.controls["unitMakeName"].value) {

      if (this.editEquipForm.controls["unitMakeName"].value != '') {
        unitMakeNewName = this.editEquipForm.controls["unitMakeName"].value;

        let data = {
          dropdownName: 'UNITMAKE',
          optionName: this.editEquipForm.controls["unitMakeName"].value,
          activeStatus: 'N',
        }
        this.addEquipServ.addDropdownOption(data).subscribe(response => {
          if (response.statusCode == "1") {
            this.statusMessage = response.statusMessage;
            this.successModal = true;
          } else {
            this.scrollToTop = true;
            this.alertService.error(response.statusMessage);
          }
        }, err => {
          this.alertService.error(err);
          this.scrollToTop = true;
        });

      }
    } else if (this.editEquipForm.controls["unitMakeNotListed"].value == true && this.newUnitMakeValue == this.editEquipForm.controls["unitMakeName"].value) {
      unitMakeNewName = this.editEquipForm.controls["unitMakeName"].value;
    }
    else {
      unitMakeNewName = this.editEquipForm.controls["unitMake"].value;

    }

    if (this.editEquipForm.controls["applicationTypeNotListed"].value == true && this.newApplicationTypeValue != this.editEquipForm.controls["applicationTypeName"].value) {
      if (this.editEquipForm.controls["applicationTypeName"].value != '') {

        applicationTypeNewName = this.editEquipForm.controls["applicationTypeName"].value;
        let data = {
          dropdownName: 'APPLICATIONTYPE',
          optionName: this.editEquipForm.controls["applicationTypeName"].value,
          activeStatus: 'N',
        }
        this.addEquipServ.addDropdownOption(data).subscribe(response => {
          if (response.statusCode == "1") {
            this.statusMessage = response.statusMessage;
            this.successModal = true;
          } else {
            this.scrollToTop = true;
            this.alertService.error(response.statusMessage);
          }
        }, err => {
          this.alertService.error(err);
          this.scrollToTop = true;
        });

      }
    } else if (this.editEquipForm.controls["applicationTypeNotListed"].value == true && this.newApplicationTypeValue == this.editEquipForm.controls["applicationTypeName"].value) {
      applicationTypeNewName = this.editEquipForm.controls["applicationTypeName"].value;
    } else {
      applicationTypeNewName = this.editEquipForm.controls["applicationType"].value;
    }

    if (this.editEquipForm.controls["engineMakeNotListed"].value == true && this.editEquipForm.controls["engineModelNotListed"].value == true && this.newEngineMakeValue != this.editEquipForm.controls["engineMakeName"].value && this.newEngineModelValue != this.editEquipForm.controls["engineModelName"].value) {
      //if(this.editEquipForm.controls["engineMakeName"].value != '' ||  this.editEquipForm.controls["engineModelName"].value != ''){
      engineMakeNewName = this.editEquipForm.controls["engineMakeName"].value;
      engineModelNewName = this.editEquipForm.controls["engineModelName"].value;

      let data = {
        dropdownName: 'ENGINEMAKEMODEL',
        engineMake: engineMakeNewName,
        engineModel: engineModelNewName,
        activeStatus: 'N',
        engModStatus: 'N'
      }
      this.addEquipServ.addDropdownOption(data).subscribe(response => {
        if (response.statusCode == "1") {
          this.statusMessage = response.statusMessage;
          this.successModal = true;
        } else {
          this.scrollToTop = true;
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      });

    } else if (this.editEquipForm.controls["engineMakeNotListed"].value == true && this.editEquipForm.controls["engineModelNotListed"].value == true && this.newEngineMakeValue == this.editEquipForm.controls["engineMakeName"].value && this.newEngineModelValue == this.editEquipForm.controls["engineModelName"].value) {
      //if(this.editEquipForm.controls["engineMakeName"].value != '' ||  this.editEquipForm.controls["engineModelName"].value != ''){
      engineMakeNewName = this.editEquipForm.controls["engineMakeName"].value;
      engineModelNewName = this.editEquipForm.controls["engineModelName"].value;
    } else if (this.editEquipForm.controls["engineMakeNotListed"].value == true && this.editEquipForm.controls["engineModelNotListed"].value == true && this.newEngineMakeValue != this.editEquipForm.controls["engineMakeName"].value && this.newEngineModelValue == this.editEquipForm.controls["engineModelName"].value) {
      //if(this.editEquipForm.controls["engineMakeName"].value != '' ||  this.editEquipForm.controls["engineModelName"].value != ''){
      engineMakeNewName = this.editEquipForm.controls["engineMakeName"].value;
      engineModelNewName = this.editEquipForm.controls["engineModelName"].value;

      let data = {
        dropdownName: 'ENGINEMAKEMODEL',
        engineMake: engineMakeNewName,
        engineModel: engineModelNewName,
        activeStatus: 'N',
        engModStatus: 'N'
      }
      this.addEquipServ.addDropdownOption(data).subscribe(response => {
        if (response.statusCode == "1") {
          this.statusMessage = response.statusMessage;
          this.successModal = true;
        } else {
          this.scrollToTop = true;
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      });
    }
    else if (this.editEquipForm.controls["engineMakeNotListed"].value == true && this.editEquipForm.controls["engineModelNotListed"].value == true && this.newEngineMakeValue == this.editEquipForm.controls["engineMakeName"].value && this.newEngineModelValue != this.editEquipForm.controls["engineModelName"].value) {
      //if(this.editEquipForm.controls["engineMakeName"].value != '' ||  this.editEquipForm.controls["engineModelName"].value != ''){
      engineMakeNewName = this.editEquipForm.controls["engineMakeName"].value;
      engineModelNewName = this.editEquipForm.controls["engineModelName"].value;

      let data = {
        dropdownName: 'ENGINEMAKEMODEL',
        engineMake: engineMakeNewName,
        engineModel: engineModelNewName,
        activeStatus: 'N',
        engModStatus: 'N'
      }
      this.addEquipServ.addDropdownOption(data).subscribe(response => {
        if (response.statusCode == "1") {
          this.statusMessage = response.statusMessage;
          this.successModal = true;
        } else {
          this.scrollToTop = true;
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      });
    }
    else if (this.editEquipForm.controls["engineMakeNotListed"].value != true && this.editEquipForm.controls["engineModelNotListed"].value == true && this.newEngineModelValue == this.editEquipForm.controls["engineModelName"].value && this.newEngineMakeValue == this.editEquipForm.controls["engineMake"]) {

      engineModelNewName = this.editEquipForm.controls["engineModelName"].value;
      engineMakeNewName = this.editEquipForm.controls["engineMake"].value;

      // let data = {
      //   dropdownName: 'ENGINEMAKEMODEL',
      //   engineMake:engineMakeNewName,
      //   engineModel:engineModelNewName,
      //   activeStatus: 'Y',
      //   engModStatus: 'N',
      // }
      //   this.addEquipServ.addDropdownOption(data).subscribe(response =>{
      //     if (response.statusCode == "1") {
      //       this.statusMessage = response.statusMessage;
      //       this.successModal = true;
      //     } else {
      //       this.scrollToTop = true;
      //       this.alertService.error(response.statusMessage);
      //     }
      // },err => {
      //   this.alertService.error(err);        
      //   this.scrollToTop = true;
      // });
    } else if (this.editEquipForm.controls["engineMakeNotListed"].value == true && this.editEquipForm.controls["engineModelNotListed"].value != true && this.newEngineMakeValue == this.editEquipForm.controls["engineMakeName"].value) {

      engineModelNewName = this.editEquipForm.controls["engineModelName"].value;
      engineMakeNewName = this.editEquipForm.controls["engineMake"].value;
      // } else if(this.editEquipForm.controls["engineMakeNotListed"].value != true && this.editEquipForm.controls["engineModelNotListed"].value == true &&  this.newEngineModelValue == this.editEquipForm.controls["engineModelName"].value ){

      //   engineModelNewName = this.editEquipForm.controls["engineModelName"].value;
      //   engineMakeNewName = this.editEquipForm.controls["engineMake"].value;

      // }
    } else if (this.editEquipForm.controls["engineMakeNotListed"].value != true && this.editEquipForm.controls["engineModelNotListed"].value == true && this.newEngineModelValue != this.editEquipForm.controls["engineModelName"].value) {

      engineModelNewName = this.editEquipForm.controls["engineModelName"].value;
      engineMakeNewName = this.editEquipForm.controls["engineMake"].value;

      let data = {
        dropdownName: 'ENGINEMAKEMODEL',
        engineMake: engineMakeNewName,
        engineModel: engineModelNewName,
        activeStatus: 'Y',
        engModStatus: 'N'
      }
      this.addEquipServ.addDropdownOption(data).subscribe(response => {
        if (response.statusCode == "1") {
          this.statusMessage = response.statusMessage;
          this.successModal = true;
        } else {
          this.scrollToTop = true;
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      });
    } else if (this.editEquipForm.controls["engineMakeNotListed"].value == true && this.editEquipForm.controls["engineModelNotListed"].value != true && this.newEngineMakeValue != this.editEquipForm.controls["engineMakeName"].value) {
      engineMakeNewName = this.editEquipForm.controls["engineMakeName"].value;
      engineModelNewName = this.editEquipForm.controls["engineModel"].value;
      let data = {
        dropdownName: 'ENGINEMAKEMODEL',
        engineMake: engineMakeNewName,
        engineModel: engineModelNewName,
        activeStatus: 'N',
        engModStatus: 'Y',
      }
      this.addEquipServ.addDropdownOption(data).subscribe(response => {
        if (response.statusCode == "1") {
          this.statusMessage = response.statusMessage;
          this.successModal = true;
        } else {
          this.scrollToTop = true;
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      });
    } else if (this.editEquipForm.controls["engineMakeNotListed"].value == true && this.editEquipForm.controls["engineModelNotListed"].value != true && this.newEngineMakeValue != this.editEquipForm.controls["engineMakeName"].value) {

      engineModelNewName = this.editEquipForm.controls["engineModel"].value;
      engineMakeNewName = this.editEquipForm.controls["engineMakeName"].value;

      let data = {
        dropdownName: 'ENGINEMAKEMODEL',
        engineMake: engineMakeNewName,
        engineModel: engineModelNewName,
        activeStatus: 'N',
        engModStatus: 'Y'
      }
      this.addEquipServ.addDropdownOption(data).subscribe(response => {
        if (response.statusCode == "1") {
          this.statusMessage = response.statusMessage;
          this.successModal = true;
        } else {
          this.scrollToTop = true;
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      });


    } else if (this.editEquipForm.controls["engineMakeNotListed"].value != true && this.editEquipForm.controls["engineModelNotListed"].value == true && this.newEngineModelValue == this.editEquipForm.controls["engineModelName"].value && this.newEngineMakeValue != this.editEquipForm.controls["engineMake"].value) {

      engineModelNewName = this.editEquipForm.controls["engineModelName"].value;
      engineMakeNewName = this.editEquipForm.controls["engineMake"].value;

      let data = {
        dropdownName: 'ENGINEMAKEMODEL',
        engineMake: engineMakeNewName,
        engineModel: engineModelNewName,
        activeStatus: 'Y',
        engModStatus: 'N'
      }
      this.addEquipServ.addDropdownOption(data).subscribe(response => {
        if (response.statusCode == "1") {
          this.statusMessage = response.statusMessage;
          this.successModal = true;
        } else {
          this.scrollToTop = true;
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      });

    } else if (this.editEquipForm.controls["engineMakeNotListed"].value != true && this.editEquipForm.controls["engineModelNotListed"].value == true && this.newEngineModelValue == this.editEquipForm.controls["engineModelName"].value && this.newEngineMakeValue == this.editEquipForm.controls["engineMake"].value) {

      engineModelNewName = this.editEquipForm.controls["engineModelName"].value;
      engineMakeNewName = this.editEquipForm.controls["engineMake"].value;
    }



    if (this.editEquipForm.controls["engineMakeNotListed"].value != true && this.editEquipForm.controls["engineModelNotListed"].value != true) {

      engineMakeNewName = this.editEquipForm.controls["engineMake"].value
      engineModelNewName = this.editEquipForm.controls["engineModel"].value
    }


    this.trimEmptySpace();
    if (this.userRole === USERTYPE.LAB_ADMIN || this.userRole == USERTYPE.DATA_SCIENTIST || this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS || this.userRole == USERTYPE.FLUIDWATCH_MANAGER) {
      this.companyNameList = this.companyIdList.find(i => i.companyId == this.editEquipForm.controls["companyId"].value)
      let companyName = this.companyNameList.companyName;
      this.editEquipForm.patchValue({
        companyName: companyName,
      })
    } else if (this.userRole === USERTYPE.EXTERNAL) {
      let companyName = JSON.parse(localStorage.getItem('Externalusercompanyname')).companyName;      
      this.editEquipForm.patchValue({
        companyName: companyName,
      })
    }
    // this.editEquipForm.setValue({
    //   companyName: companyName
    // });
    // console.log(companyName, 'abc');
    this.editEquipForm.patchValue({
      //isReviewUnit: this.editEquipForm.controls["isReviewUnit"].value ? 'Y' : 'N',

      unitMake: unitMakeNewName,
      applicationType: applicationTypeNewName,
      engineMake: engineMakeNewName,
      engineModel: engineModelNewName,
    })
    if (this.editEquipForm.invalid) {   
      if(this.editEquipForm.controls["companyId"].value !== '' && this.editEquipForm.controls["applicationType"].value !== '' && this.editEquipForm.controls["unitId"].value !== '' && this.editEquipForm.controls["unitMake"].value !== '' && this.editEquipForm.controls["unitModel"].value !== '' && this.editEquipForm.controls["selectedComponent"] === undefined){
        const reqParam = {
          "unit": {
            "unitId": this.editEquipForm.controls['unitId'].value? this.editEquipForm.controls['unitId'].value : '',
            "esn": this.editEquipForm.controls['equipSerialNo'].value? this.editEquipForm.controls['equipSerialNo'].value : '',
            "companyId": this.editEquipForm.controls['companyId'].value? this.editEquipForm.controls['companyId'].value: '',
            "companyName": this.editEquipForm.controls['companyName'].value? this.editEquipForm.controls['companyName'].value: '',
            "applicationType": this.editEquipForm.controls['applicationType'].value? this.editEquipForm.controls['applicationType'].value : '',
            "userId": this.editEquipForm.controls['userId'].value? this.editEquipForm.controls['userId'].value : '',
            "isReviewUnit":  this.editEquipForm.controls["isReviewUnit"].value ? 'Y' : 'N',
            "unitMake": this.editEquipForm.controls['unitMake'].value? this.editEquipForm.controls['unitMake'].value : '',
            "unitModel": this.editEquipForm.controls['unitModel'].value? this.editEquipForm.controls['unitModel'].value : '',
            "unitYear": this.editEquipForm.controls['unitYear'].value? this.editEquipForm.controls['unitYear'].value : '',
          },
          "components": ''
        }
        
        console.log('reqParam',reqParam);
        this.addEquipServ.updateEquipment(reqParam).subscribe(response => {
          console.log(this.editEquipForm.getRawValue(), "editEquipForm");
          if (response.statusCode == "1") {
            this.statusMessage = response.statusMessage;
            this.successModal = true;
          } else {
            this.scrollToTop = true;
            this.alertService.error(response.statusMessage);
          }
        }, err => {
          this.scrollToTop = true;
          this.alertService.error(err);
        });
    
      }
      return false;      
    }
    this.gridValues = [];
    console.log("this.newArr : ", this.newArr);
    this.newArr.forEach((_value,i) => {
      this.newArr[i].companyId = this.editEquipForm.controls['companyId'].value? this.editEquipForm.controls['companyId'].value: '';
        const componentType = this.componentTypeList.filter(row => {
          return (row.componentType == this.newArr[i].componentName);
        });
        console.log("componentType",componentType); 
        this.gridValues.push({
          "equipmentId": (this.newArr[i].equipmentId !== undefined)? this.newArr[i].equipmentId: '',
          "componentId":this.newArr[i].componentId,
          "secondaryUnitId": this.newArr[i].secondaryId?this.newArr[i].secondaryId:'',
          "componentType": componentType[0].id,
          "componentMake": this.newArr[i].Make?  this.newArr[i].Make: '',
          "componentModel": this.newArr[i].Model?  this.newArr[i].Model: '',
          "componentYear": this.newArr[i].Year?  this.newArr[i].Year: '',
          "distanceUom":this.newArr[i].unitMeasure? this.newArr[i].unitMeasure: '',
          "engineType": (this.editEquipForm.controls['engineType'].value && this.newArr[i].componentName.toUpperCase() === 'ENGINE')? this.editEquipForm.controls['engineType'].value: '',
          "csn": this.newArr[i].serialNo? this.newArr[i].serialNo: '',
        //  "esn": this.newArr[i].serialNo? this.newArr[i].serialNo: '',

          "fluidTypeForOil": this.newArr[i].fluidTypeForOil? this.newArr[i].fluidTypeForOil: '',
          'fluidBrandForOil': this.newArr[i].fluidBrandForOil? this.newArr[i].fluidBrandForOil: '',
          'fluidProductForOil': this.newArr[i].fluidProductForOil? this.newArr[i].fluidProductForOil: '',
          'fluidGradeForOil': this.newArr[i].fluidGradeForOil? this.newArr[i].fluidGradeForOil: '',
          'filterBrandForOil':  this.newArr[i].filterBrandForOil? this.newArr[i].filterBrandForOil: '',
          'filterProductForOil': this.newArr[i].filterProductForOil? this.newArr[i].filterProductForOil: '',

          'fluidTypeForCoolant': this.newArr[i].fluidTypeForCoolant? this.newArr[i].fluidTypeForCoolant: '',
          'fluidBrandForCoolant': this.newArr[i].fluidBrandForCoolant? this.newArr[i].fluidBrandForCoolant: '',
          'fluidProductForCoolant':  this.newArr[i].fluidProductForCoolant? this.newArr[i].fluidProductForCoolant: '',
          'fluidGradeForCoolant': this.newArr[i].fluidGradeForCoolant? this.newArr[i].fluidGradeForCoolant: '',
          'filterBrandForCoolant':  this.newArr[i].filterBrandForCoolant? this.newArr[i].filterBrandForCoolant: '',
          'filterProductForCoolant': this.newArr[i].filterProductForCoolant? this.newArr[i].filterProductForCoolant: '',

          'fluidTypeForFuel': this.newArr[i].fluidTypeForFuel? this.newArr[i].fluidTypeForFuel: '',
          'fluidGradeForFuel': this.newArr[i].fluidGradeForFuel? this.newArr[i].fluidGradeForFuel: '',
          'filterBrandForFuel': this.newArr[i].filterBrandForFuel? this.newArr[i].filterBrandForFuel: '',
          'filterProductForFuel': this.newArr[i].filterProductForFuel? this.newArr[i].filterProductForFuel:'',

          
          'fluidTypeForDef': this.newArr[i].fluidTypeForDef? this.newArr[i].fluidTypeForDef: '',
          'fluidBrandForDef': this.newArr[i].fluidBrandForDef? this.newArr[i].fluidBrandForDef: '',  
          'fluidProductForDef': this.newArr[i].fluidProductForDef? this.newArr[i].fluidProductForDef: '',      
          'fluidGradeForDef': this.newArr[i].fluidGradeForDef? this.newArr[i].fluidGradeForDef: '',

          'isDefChecked': this.newArr[i].isDefChecked == true? true: false,
          'isOilChecked': this.newArr[i].isOilChecked == true? true: false,
          'isCoolantChecked': this.newArr[i].isCoolantChecked == true? true:  false,
          'isFuelChecked': this.newArr[i].isFuelChecked == true? true: false,
        });
    })
    const reqParam = {
      "unit": {
        "unitId": this.editEquipForm.controls['unitId'].value? this.editEquipForm.controls['unitId'].value : '',
        "esn": this.editEquipForm.controls['equipSerialNo'].value? this.editEquipForm.controls['equipSerialNo'].value : '',
        "companyId": this.editEquipForm.controls['companyId'].value? this.editEquipForm.controls['companyId'].value: '',
        "companyName": this.editEquipForm.controls['companyName'].value? this.editEquipForm.controls['companyName'].value: '',
        "applicationType": applicationTypeNewName? applicationTypeNewName : '',
        "userId": this.editEquipForm.controls['userId'].value? this.editEquipForm.controls['userId'].value : '',
        "isReviewUnit":  this.editEquipForm.controls["isReviewUnit"].value ? 'Y' : 'N',
        "unitMake": unitMakeNewName? unitMakeNewName: '',
        "unitModel": this.editEquipForm.controls['unitModel'].value? this.editEquipForm.controls['unitModel'].value : '',
        "unitYear": this.editEquipForm.controls['unitYear'].value? this.editEquipForm.controls['unitYear'].value : '',        
        "bulkTankFuelStorage":(this.editEquipForm.controls["bulkTankFuelStorage"].value == true)? true: false,
        "unitNo": this.sharedService.getInformation().unitNo
      },
      "components": this.gridValues
    }
    
    console.log('reqParam For Update',reqParam);


    this.addEquipServ.updateEquipment(reqParam).subscribe(response => {
      if (response.statusCode == "1") {
        this.statusMessage = response.statusMessage;
        this.successModal = true;
      } else {
        this.scrollToTop = true;
        this.alertService.error(response.statusMessage);
        this.showGrid = false;
        this.gridValues = [];
        this.newArr = [];
      }
    }, err => {
      this.scrollToTop = true;
      this.alertService.error(err);
    });
  }

  dbuRoleConfirmation() {
    this.isdbuRoleConfirmation = true;
  }
  closeModal() {
    this.isdbuRoleConfirmation = false;
  }

  addVehicle() {
    this.isdbuRoleConfirmation = false;
    let unitMakeNewName;
    let applicationTypeNewName;
    let engineMakeNewName;
    let engineModelNewName;

    if (this.editEquipForm.controls["unitMakeNotListed"].value == true) {

      if (this.editEquipForm.controls["unitMakeName"].value != '') {
        unitMakeNewName = this.editEquipForm.controls["unitMakeName"].value;

        let data = {
          dropdownName: 'UNITMAKE',
          optionName: this.editEquipForm.controls["unitMakeName"].value,
          activeStatus: 'N',
        }
        this.addEquipServ.addDropdownOption(data).subscribe(response => {
          if (response.statusCode == "1") {
            this.statusMessage = response.statusMessage;
            this.successModal = true;
          } else {
            this.scrollToTop = true;
            this.alertService.error(response.statusMessage);
          }
        }, err => {
          this.alertService.error(err);
          this.scrollToTop = true;
        });

      }
    } else {
      unitMakeNewName = this.editEquipForm.controls["unitMake"].value
    }

    if (this.editEquipForm.controls["applicationTypeNotListed"].value == true) {
      if (this.editEquipForm.controls["applicationTypeName"].value != '') {

        applicationTypeNewName = this.editEquipForm.controls["applicationTypeName"].value;
        let data = {
          dropdownName: 'APPLICATIONTYPE',
          optionName: this.editEquipForm.controls["applicationTypeName"].value,
          activeStatus: 'N',
        }
        this.addEquipServ.addDropdownOption(data).subscribe(response => {
          if (response.statusCode == "1") {
            this.statusMessage = response.statusMessage;
            this.successModal = true;
          } else {
            this.scrollToTop = true;
            this.alertService.error(response.statusMessage);
          }
        }, err => {
          this.alertService.error(err);
          this.scrollToTop = true;
        });

      }
    } else {
      applicationTypeNewName = this.editEquipForm.controls["applicationType"].value;
    }
if(this.editEquipForm.controls["engineType"].value !== '' || this.editEquipForm.controls["engineType"].value !== undefined){
    if (this.editEquipForm.controls["engineMakeNotListed"].value == true && this.editEquipForm.controls["engineModelNotListed"].value == true) {
      //if(this.editEquipForm.controls["engineMakeName"].value != '' ||  this.editEquipForm.controls["engineModelName"].value != ''){
      engineMakeNewName = this.editEquipForm.controls["engineMakeName"].value;
      engineModelNewName = this.editEquipForm.controls["engineModelName"].value;

      let data = {
        dropdownName: 'ENGINEMAKEMODEL',
        engineMake: engineMakeNewName,
        engineModel: engineModelNewName,
        activeStatus: 'N',
        engModStatus: 'N'
      }
      this.addEquipServ.addDropdownOption(data).subscribe(response => {
        if (response.statusCode == "1") {
          this.statusMessage = response.statusMessage;
          this.successModal = true;
        } else {
          this.scrollToTop = true;
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      });

    } else if (this.editEquipForm.controls["engineMakeNotListed"].value != true && this.editEquipForm.controls["engineModelNotListed"].value == true && this.editEquipForm.controls["engineMake"].value != '' && this.editEquipForm.controls["engineModel"].value == '') {

      engineModelNewName = this.editEquipForm.controls["engineModelName"].value;
      engineMakeNewName = this.editEquipForm.controls["engineMake"].value;

      let data = {
        dropdownName: 'ENGINEMAKEMODEL',
        engineMake: engineMakeNewName,
        engineModel: engineModelNewName,
        activeStatus: 'Y',
        engModStatus: 'N'
      }
      this.addEquipServ.addDropdownOption(data).subscribe(response => {
        if (response.statusCode == "1") {
          this.statusMessage = response.statusMessage;
          this.successModal = true;
        } else {
          this.scrollToTop = true;
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      });
    } else if (this.editEquipForm.controls["engineMakeNotListed"].value == true && this.editEquipForm.controls["engineModelNotListed"].value != true && this.editEquipForm.controls["engineModel"].value != '' && this.editEquipForm.controls["engineMake"].value == '') {
      engineMakeNewName = this.editEquipForm.controls["engineMakeName"].value;
      engineModelNewName = this.editEquipForm.controls["engineModel"].value;
      let data = {
        dropdownName: 'ENGINEMAKEMODEL',
        engineMake: engineMakeNewName,
        engineModel: engineModelNewName,
        activeStatus: 'N',
        engModStatus: 'Y'
      }
      this.addEquipServ.addDropdownOption(data).subscribe(response => {
        if (response.statusCode == "1") {
          this.statusMessage = response.statusMessage;
          this.successModal = true;
        } else {
          this.scrollToTop = true;
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      });
    }
    if (this.editEquipForm.controls["engineMakeNotListed"].value != true && this.editEquipForm.controls["engineModelNotListed"].value != true) {

      engineMakeNewName = this.editEquipForm.controls["engineMake"].value
      engineModelNewName = this.editEquipForm.controls["engineModel"].value
    }
  } else {
    if (this.editEquipForm.controls["engineMakeNotListed"].value == true) {
      //if(this.editEquipForm.controls["engineMakeName"].value != '' ||  this.editEquipForm.controls["engineModelName"].value != ''){
      engineMakeNewName = this.editEquipForm.controls["engineMakeName"].value;
      //engineModelNewName = this.editEquipForm.controls["engineModelName"].value;
      let data = {
        dropdownName: 'ENGINEMAKEMODEL',
        engineMake: engineMakeNewName,
        engineModel: engineModelNewName,
        activeStatus: 'N',
        engModStatus: 'N'
      }
      this.addEquipServ.addDropdownOption(data).subscribe(response => {
        if (response.statusCode == "1") {
          this.statusMessage = response.statusMessage;
          this.successModal = true;
        } else {
          this.scrollToTop = true;
          this.alertService.error(response.statusMessage);
        }        
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      });
     
    } else if (this.editEquipForm.controls["engineMake"].value != '') {

      //engineModelNewName = this.editEquipForm.controls["engineModelName"].value;
      engineMakeNewName = this.editEquipForm.controls["engineMake"].value;
      let data = {
        dropdownName: 'ENGINEMAKEMODEL',
        engineMake: engineMakeNewName,
        engineModel: engineModelNewName,
        activeStatus: 'Y',
        engModStatus: 'N'
      }
      this.addEquipServ.addDropdownOption(data).subscribe(response => {
        if (response.statusCode == "1") {
          this.statusMessage = response.statusMessage;
          this.successModal = true;
        } else {
          this.scrollToTop = true;
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      });
      
    } else if (this.editEquipForm.controls["engineMakeNotListed"].value == true && this.editEquipForm.controls["engineMake"].value == '') {
      engineMakeNewName = this.editEquipForm.controls["engineMakeName"].value;
      //engineModelNewName = this.editEquipForm.controls["engineModel"].value;
      let data = {
        dropdownName: 'ENGINEMAKEMODEL',
        engineMake: engineMakeNewName,
        engineModel: engineModelNewName,
        activeStatus: 'N',
        engModStatus: 'Y'
      }
      this.addEquipServ.addDropdownOption(data).subscribe(response => {
        if (response.statusCode == "1") {
          this.statusMessage = response.statusMessage;
          this.successModal = true;
        } else {
          this.scrollToTop = true;
          this.alertService.error(response.statusMessage);
        }
      }, err => {
        this.alertService.error(err);
        this.scrollToTop = true;
      });
      
    }
    if (this.editEquipForm.controls["engineMakeNotListed"].value != true) {

      engineMakeNewName = this.editEquipForm.controls["engineMake"].value
      //engineModelNewName = this.editEquipForm.controls["engineModel"].value
    }
  }
    this.trimEmptySpace();
    if (this.userRole === USERTYPE.LAB_ADMIN || this.userRole == USERTYPE.DATA_SCIENTIST || this.userRole == USERTYPE.DBU_BRANCH || this.userRole == USERTYPE.DBU_FLUIDS || this.userRole == USERTYPE.FLUIDWATCH_MANAGER) {
      this.companyNameList = this.companyIdList.find(i => i.companyId == this.editEquipForm.controls["companyId"].value)
      let companyName = this.companyNameList.companyName;
      this.editEquipForm.patchValue({
        companyName: companyName,
      })
    }
    else if (this.userRole === USERTYPE.EXTERNAL) {
      if(this.mode == 'add'){
        this.companyNameList = this.companyIdList.find(i => i.companyId == this.editEquipForm.controls["companyId"].value)
        this.companyName = this.companyNameList.companyName;
        this.editEquipForm.patchValue({
          companyName: this.companyName,
        })
      } else{
        this.companyName = JSON.parse(localStorage.getItem('Externalusercompanyname')).companyName;           
        this.editEquipForm.patchValue({
        companyName: this.companyName,
      })
    }
    }

    this.editEquipForm.patchValue({
      //isReviewUnit: this.editEquipForm.controls["isReviewUnit"].value ? 'Y' : 'N',
      // unitMakeFlag: this.editEquipForm.controls["unitMakeNotListed"].value ? 'N' : 'Y',
      unitMake: unitMakeNewName,
      applicationType: applicationTypeNewName,
      engineMake: engineMakeNewName,
      engineModel: engineModelNewName,
    })
    if (this.editEquipForm.invalid) {   
      if(this.editEquipForm.controls["companyId"].value !== '' && this.editEquipForm.controls["applicationType"].value !== '' && this.editEquipForm.controls["unitId"].value !== '' && this.editEquipForm.controls["unitMake"].value !== '' && this.editEquipForm.controls["unitModel"].value !== '' && this.editEquipForm.controls["equipSerialNo"].value !== ''&& this.editEquipForm.controls["selectedComponent"].value === ''){
        
        const reqParam = {
          "unit": {
            "unitId": this.editEquipForm.controls['unitId'].value? this.editEquipForm.controls['unitId'].value : '',
            "esn": this.editEquipForm.controls['equipSerialNo'].value? this.editEquipForm.controls['equipSerialNo'].value : '',
            "companyId": this.editEquipForm.controls['companyId'].value? this.editEquipForm.controls['companyId'].value: '',
            "companyName": this.editEquipForm.controls['companyName'].value? this.editEquipForm.controls['companyName'].value: '',   
            "applicationType": this.editEquipForm.controls['applicationType'].value? this.editEquipForm.controls['applicationType'].value : '',
            "userId": this.editEquipForm.controls['userId'].value? this.editEquipForm.controls['userId'].value : '',
            "isReviewUnit":  this.editEquipForm.controls["isReviewUnit"].value ? 'Y' : 'N',
            "unitMake": this.editEquipForm.controls['unitMake'].value? this.editEquipForm.controls['unitMake'].value : '',
            "unitModel": this.editEquipForm.controls['unitModel'].value? this.editEquipForm.controls['unitModel'].value : '',
            "unitYear": this.editEquipForm.controls['unitYear'].value? this.editEquipForm.controls['unitYear'].value : '',
          },
          "components": ''
        }
        
        console.log('reqParam',reqParam);
        this.addEquipServ.addEquipment(reqParam).subscribe(response => {
          console.log(this.editEquipForm.getRawValue(), "editEquipForm");
          if (response.statusCode == "1") {
            this.statusMessage = response.statusMessage;
            this.successModal = true;
          } else {
            this.scrollToTop = true;
            this.alertService.error(response.statusMessage);
          }
        }, err => {
          this.scrollToTop = true;
          this.alertService.error(err);
        });
    
      }
        return false;
      
    }    
    this.newArr.forEach((_value,i) => {
      this.newArr[i].companyId = this.editEquipForm.controls['companyId'].value? this.editEquipForm.controls['companyId'].value: '';
        const componentType = this.componentTypeList.filter(row => {
          return (row.componentType == this.newArr[i].componentName);
        });
        console.log("componentType",componentType); 
        this.gridValues.push({
          "componentId":this.newArr[i].componentId,
          "secondaryUnitId": this.newArr[i].secondaryId?this.newArr[i].secondaryId:'',
          "componentType": componentType[0].id,
          "componentMake": this.newArr[i].Make?  this.newArr[i].Make: '',
          "componentModel": this.newArr[i].Model?  this.newArr[i].Model: '',
          "componentYear": this.newArr[i].Year?  this.newArr[i].Year: '',
          "distanceUom":this.newArr[i].unitMeasure? this.newArr[i].unitMeasure: '',
          "engineType": (this.editEquipForm.controls['engineType'].value && this.newArr[i].componentName.toUpperCase() === 'ENGINE')? this.editEquipForm.controls['engineType'].value: '',
          "csn": this.newArr[i].serialNo? this.newArr[i].serialNo: '',
        //  "esn": this.newArr[i].serialNo? this.newArr[i].serialNo: '',

          "fluidTypeForOil": this.newArr[i].fluidTypeForOil? this.newArr[i].fluidTypeForOil: '',
          'fluidBrandForOil': this.newArr[i].fluidBrandForOil? this.newArr[i].fluidBrandForOil: '',
          'fluidProductForOil': this.newArr[i].fluidProductForOil? this.newArr[i].fluidProductForOil: '',
          'fluidGradeForOil': this.newArr[i].fluidGradeForOil? this.newArr[i].fluidGradeForOil: '',
          'filterBrandForOil':  this.newArr[i].filterBrandForOil? this.newArr[i].filterBrandForOil: '',
          'filterProductForOil': this.newArr[i].filterProductForOil? this.newArr[i].filterProductForOil: '',

          'fluidTypeForCoolant': this.newArr[i].fluidTypeForCoolant? this.newArr[i].fluidTypeForCoolant: '',
          'fluidBrandForCoolant': this.newArr[i].fluidBrandForCoolant? this.newArr[i].fluidBrandForCoolant: '',
          'fluidProductForCoolant':  this.newArr[i].fluidProductForCoolant? this.newArr[i].fluidProductForCoolant: '',
          'fluidGradeForCoolant': this.newArr[i].fluidGradeForCoolant? this.newArr[i].fluidGradeForCoolant: '',
          'filterBrandForCoolant':  this.newArr[i].filterBrandForCoolant? this.newArr[i].filterBrandForCoolant: '',
          'filterProductForCoolant': this.newArr[i].filterProductForCoolant? this.newArr[i].filterProductForCoolant: '',

          'fluidTypeForFuel': this.newArr[i].fluidTypeForFuel? this.newArr[i].fluidTypeForFuel: '',
          'fluidGradeForFuel': this.newArr[i].fluidGradeForFuel? this.newArr[i].fluidGradeForFuel: '',
          'filterBrandForFuel': this.newArr[i].filterBrandForFuel? this.newArr[i].filterBrandForFuel: '',
          'filterProductForFuel': this.newArr[i].filterProductForFuel? this.newArr[i].filterProductForFuel:'',

          
          'fluidTypeForDef': this.newArr[i].fluidTypeForDef? this.newArr[i].fluidTypeForDef: '',
          'fluidBrandForDef': this.newArr[i].fluidBrandForDef? this.newArr[i].fluidBrandForDef: '',  
          'fluidProductForDef': this.newArr[i].fluidProductForDef? this.newArr[i].fluidProductForDef: '',      
          'fluidGradeForDef': this.newArr[i].fluidGradeForDef? this.newArr[i].fluidGradeForDef: '',

          'isDefChecked': this.newArr[i].isDefChecked == true? true: false,
          'isOilChecked': this.newArr[i].isOilChecked == true? true: false,
          'isCoolantChecked': this.newArr[i].isCoolantChecked == true? true:  false,
          'isFuelChecked': this.newArr[i].isFuelChecked == true? true: false,
        });
    })
    const reqParam = {
      "unit": {
        "unitId": this.editEquipForm.controls['unitId'].value? this.editEquipForm.controls['unitId'].value : '',
        "esn": this.editEquipForm.controls['equipSerialNo'].value? this.editEquipForm.controls['equipSerialNo'].value : '',
        "companyId": this.editEquipForm.controls['companyId'].value? this.editEquipForm.controls['companyId'].value: '',
        "companyName": this.editEquipForm.controls['companyName'].value? this.editEquipForm.controls['companyName'].value: '',
        "applicationType": applicationTypeNewName? applicationTypeNewName : '',
        "userId": this.editEquipForm.controls['userId'].value? this.editEquipForm.controls['userId'].value : '',
        "isReviewUnit":  this.editEquipForm.controls["isReviewUnit"].value ? 'Y' : 'N',
        "unitMake": unitMakeNewName? unitMakeNewName: '',
        "unitModel": this.editEquipForm.controls['unitModel'].value? this.editEquipForm.controls['unitModel'].value : '',
        "unitYear": this.editEquipForm.controls['unitYear'].value? this.editEquipForm.controls['unitYear'].value : '',        
        "bulkTankFuelStorage":(this.editEquipForm.controls["bulkTankFuelStorage"].value == true)? true: false,
      },
      "components": this.gridValues
    }
    
    console.log('reqParam for add Component',reqParam);
    this.addEquipServ.addEquipment(reqParam).subscribe(response => {
      console.log(this.editEquipForm.getRawValue(), "editEquipForm");
      if (response.statusCode == "1") {
        this.statusMessage = response.statusMessage;
        this.successModal = true;
      } else {
        this.scrollToTop = true;
        this.alertService.error(response.statusMessage);
        this.showGrid = false;
        this.gridValues = [];
        this.newArr = [];
      }
    }, err => {
      this.scrollToTop = true;
      this.alertService.error(err);
    });

  }

  getEquipmentDropdownData(): Observable<any> {
    let dropDownData = {
      "userRole": this.userRole
    }
    dropDownData.userRole = this.sharedService.getIntOrExtUser();
    return this.addEquipServ.getEquipmentDropdownData(dropDownData)
  }


  setEquipmentDropdownData(response) {
    console.log("setEquipmentDropdownData Response : ", response);
    this.dropdownForEngineList = response;
    this.distanceUOMList = response.distanceUOMList;
    this.distanceUOMListArray = this.distanceUOMList;
    this.unitMakeList = response.unitMakeList;
    this.unitMakeArray = this.unitMakeList;
    this.engMakeModelList = response.engMakeModelList;
    //console.log(this.engMakeModelList, 'engMakeModelList'),


    this.engineMakeList = response.engineMakeList;
    this.engineMakeListArray = this.engineMakeList;
    this.engineModelList = response.engineModelList;
    this.engineModelListArray = response.engineModelList;
    this.applicationTypeList = response.applicationTypeList;
    this.applicationTypeListArray = this.applicationTypeList;
    this.unitYearList = response.unitYearList;
    this.unitYearListArray = response.unitYearList;
    this.engineYearList = response.engineYearList;
    this.engineYearListArray = this.engineYearList;
    this.engineTypeList = response.engineTypeList;
    // this.engineTypeListArray = this.engineTypeList;
    this.companyIdList = response.companyIdList;
    this.componentTypeList = response.componentTypeList;

    //console.log(" this.companyName", this.companyName);

    if (this.companyIdList != undefined) {
      this.companyIdList.forEach((value) => {
        this.dataArray.push(value)
      })
    }
    // if (this.userRole === USERTYPE.EXTERNAL) {
    //   this.companyIdIAM = JSON.parse(localStorage.getItem('externalUserInformation')).IAM_Account_Application__r.IAM_Account_Name__r.IAM_Service_Provider_Code__c;
    //   this.companyNameIAM = JSON.parse(localStorage.getItem('externalUserInformation')).IAM_Account_Application__r.IAM_Account_Name__r.Name;
    //   this.companyArrayIAM.push({ companyId: this.companyIdIAM, companyName: this.companyNameIAM })
    //   console.log("Company Arrayy:", this.companyArrayIAM);
    //   console.log("Comname:", this.companyNameIAM);
    // }
    if (this.mode == 'edit') {
      this.setEditValue();
      //this.addComponent();
      this.addComponentForm = false;
    }

  }


  onKey(value) {
    this.dataArray = [];
    this.selectSearch(value);
    // console.log("value",value)      
  }


  selectSearch(value: string) {
    let filter = value.toLowerCase();
    for (let i = 0; i < this.companyIdList.length; i++) {
      let option = this.companyIdList[i];
      if ((option.companyId.toLowerCase().indexOf(filter) >= 0) || (option.companyName.toLowerCase().indexOf(filter) >= 0)) {
        this.dataArray.push(option);
      }
    }
  }

  getFluidAndFilterDetailsForOil(): Observable<any> {
    let reqData = {
      "userRole": this.sharedService.getIntOrExtUser()
    };
    console.log("this.editEquipForm.controls.engineType : ", this.editEquipForm.controls.engineType);
    if (this.editEquipForm.controls.engineType.value == 1) {
      reqData['fluidType'] = '1';
    } else if (this.editEquipForm.controls.engineType.value == 2) {
      reqData['fluidType'] = '2';
    }

    if (this.mode == 'edit') {
      if(reqData['fluidType'] == undefined){
        reqData['fluidType'] = '1';
      }
    }

    return this.addEquipServ.getFluidAndFilterDetails(reqData);
  }

  setFluidAndFilterDetailsForOil(response) {
    this.dropdownOil = response;
    this.fluidBrandForOilList = response.fluidBrand;
    this.fluidBrandForOilListArr = this.fluidBrandForOilList;
    this.fluidProductForOilList = response.fluidProduct;
    this.fluidProductForOilListArr = this.fluidProductForOilList;
    // console.log(this.fluidProductForOilList );
    this.fluidGradeForOilList = response.fluidGrade;
    this.filterBrandForOilList = response.filterBrand;
    this.filterProductForOilList = response.filterProduct;
    this.filterProductForOilListArr = this.filterProductForOilList;
    this.fluidCombinationForOilList = response.fluidCombination;
    // console.log(this.fluidCombinationForOilList);
    this.filterCombinationForOilList = response.filterCombination;
  }

  getFluidAndFilterDetailsForCoolant(): Observable<any> {
    let reqData = {
      "userRole": this.userRole,
      //"coolant": true
      "fluidType": 3
    }
    reqData.userRole = this.sharedService.getIntOrExtUser();
    return this.addEquipServ.getFluidAndFilterDetails(reqData);
  }

  setFluidAndFilterDetailsForCoolant(response) {
    this.dropdownCoolant = response;
    this.fluidBrandForCoolantList = response.fluidBrand;
    this.fluidBrandForCoolantListArr = this.fluidBrandForCoolantList;
    this.fluidProductForCoolantList = response.fluidProduct;
    this.fluidProductForCoolantListArr = this.fluidProductForCoolantList;
    this.fluidGradeForCoolantList = response.fluidGrade;
    this.filterBrandForCoolantList = response.filterBrand;
    this.filterProductForCoolantList = response.filterProduct;
    this.filterProductForCoolantListArr = this.filterProductForCoolantList;
    this.fluidCombinationForCoolantList = response.fluidCombination;
    this.filterCombinationForCoolantList = response.filterCombination;
  }

  getFluidAndFilterDetailsForFuel(): Observable<any> {
    let reqData = {
      "userRole": this.userRole,
      //"fuel": true
      "fluidType": 4
    }
    reqData.userRole = this.sharedService.getIntOrExtUser();
    return this.addEquipServ.getFluidAndFilterDetails(reqData);
  }

  setFluidAndFilterDetailsForFuel(response) {
    this.dropdownFuel = response;
    this.fluidBrandForFuelList = response.fluidBrand;
    this.fluidProductForFuelList = response.fluidProduct;
    this.fluidGradeForFuelList = response.fluidGrade;
    this.filterBrandForFuelList = response.filterBrand;
    this.filterProductForFuelList = response.filterProduct;
    this.filterProductForFuelListArr = this.filterProductForFuelList;
    this.fluidCombinationForFuelList = response.fluidCombination;
    this.filterCombinationForFuelList = response.filterCombination;
  }

  getFluidAndFilterDetailsForDef(): Observable<any> {
    let reqData = {
      "userRole": this.userRole,
      // "def": true
      "fluidType": 5
    }
    reqData.userRole = this.sharedService.getIntOrExtUser();
    return this.addEquipServ.getFluidAndFilterDetails(reqData);
  }

  setFluidAndFilterDetailsForDef(response) {
    this.dropdownDef = response;
    this.fluidBrandForDefList = response.fluidBrand;
    this.fluidBrandForDefListArr = this.fluidBrandForDefList;
    this.fluidProductForDefList = response.fluidProduct;
    this.fluidProductForDefListArr = this.fluidProductForDefList;
    this.fluidGradeForDefList = response.fluidGrade;
    this.filterBrandForDefList = response.filterBrand;
    this.filterProductForDefList = response.filterProduct;
    this.fluidCombinationForDefList = response.fluidCombination;
    this.filterCombinationForDefList = response.filterCombination;
  }

  fluidTypeChange(field, event) {
    let fieldName = 'fluidTypeFor' + field;
    let fieldValue = '';
    let checkBoxFieldName = 'is' + field + 'Checked';
    let checkBoxFieldValue = false;
    if (event.target.checked === true) {
      fieldValue = this.fluidType[field];
      //changes for natural gas
      if (field == 'Oil') {
        if (this.editEquipForm.controls.engineType.value == 2) {
          fieldValue = this.fluidType['NatGas'];
        }
      }
      checkBoxFieldValue = true;
    }
    this.editEquipForm.patchValue({
      [fieldName]: fieldValue,
      [checkBoxFieldName]: checkBoxFieldValue
    });

    if (field == 'Oil') {
      if (event.target.checked === true) {
        this.enableFluidOil();
        this.enableFilterOil();
      } else {
        this.clearFluidOil();
        this.clearFilterOil();
        this.disableFluidOil();
        this.disableFilterOil();
      }
    } else if (field == 'Coolant') {
      if (event.target.checked === true) {
        this.enableFluidCoolant();
        this.enableFilterCoolant();
      } else {
        this.clearFluidCoolant();
        this.clearFilterCoolant();
        this.disableFluidCoolant();
        this.disableFilterCoolant();
      }
    } else if (field == 'Fuel') {
      if (event.target.checked === true) {
        this.enableFluidFuel();
        this.enableFilterFuel();
      } else {
        this.clearFluidFuel();
        this.clearFilterFuel();
        this.disableFluidFuel();
        this.disableFilterFuel();
      }
    } else if (field == 'Def') {
      if (event.target.checked === true) {
        this.enableFluidDef();
      } else {
        this.clearFluidDef();
        this.disableFluidDef();
      }
    }
  }

  clearFluidOil() {
    this.editEquipForm.patchValue({
      fluidBrandForOil: '',
      fluidProductForOil: '',
      fluidGradeForOil: '',
      // useAsPrimaryUnit : false,
      fluidBrandForOilNotListed: false,
      fluidProductForOilNotListed: false,
      fluidGradeForOilNotListed: false,

    });
    this.enableFluidOil();
    this.enableFilterOil();
    this.initialDropdownValuesFluidOil();
  }

  clearFluidCoolant() {
    this.editEquipForm.patchValue({
      fluidBrandForCoolant: '',
      fluidProductForCoolant: '',
      fluidGradeForCoolant: '',
      fluidBrandForCoolantNotListed: false,
      fluidProductForCoolantNotListed: false,
      fluidGradeForCoolantNotListed: false
    });
    if (this.mode == 'edit') {
      this.disableFluidCoolant();
      this.disableFilterCoolant();
      this.editEquipForm.patchValue({
          isCoolantChecked: false,
          fluidTypeForCoolant: ''
      });
    } else {
      if(!this.editComponentClicked) {
        this.editEquipForm.patchValue({
          isCoolantChecked: false,
          fluidTypeForCoolant: ''
        });
      }
    }
    this.initialDropdownValuesFluidCoolant();
  }

  clearFluidFuel() {
    this.editEquipForm.patchValue({
      fluidBrandForFuel: '',
      fluidProductForFuel: '',
      fluidGradeForFuel: '',
      fluidBrandForFuelNotListed: false,
      fluidProductForFuelNotListed: false,
      fluidGradeForFuelNotListed: false
    });
    if (this.mode == 'edit') {
      this.disableFluidFuel();
      this.disableFilterFuel();
      this.editEquipForm.patchValue({
        isFuelChecked: false,
        fluidTypeForFuel: ''
      });
    } else {
      if(!this.editComponentClicked) {
        this.editEquipForm.patchValue({
          isFuelChecked: false,
          fluidTypeForFuel: ''
        });
      }
    }
  }

  clearFluidDef() {
    this.editEquipForm.patchValue({
      fluidBrandForDef: '',
      fluidProductForDef: '',
      fluidGradeForDef: '',
      fluidBrandForDefNotListed: false,
      fluidProductForDefNotListed: false,
      fluidGradeForDefNotListed: false
    });
    if (this.mode == 'edit') {
      this.disableFluidDef();
      this.editEquipForm.patchValue({
        isDefChecked: false,
        fluidTypeForDef: ''
      });
    } else {
      if(!this.editComponentClicked) {
        this.editEquipForm.patchValue({
          isDefChecked: false,
          fluidTypeForDef: ''
        });
      }
    }
    this.initialDropdownValuesFluidDef();
  }

  clearFilterOil() {
    this.editEquipForm.patchValue({
      filterBrandForOil: '',
      filterProductForOil: '',
      filterBrandForOilNotListed: false,
      filterProductForOilNotListed: false
    });
  }

  clearFilterCoolant() {
    this.editEquipForm.patchValue({
      filterBrandForCoolant: '',
      filterProductForCoolant: '',
      filterBrandForCoolantNotListed: false,
      filterProductForCoolantNotListed: false
    });
  }

  clearFilterFuel() {
    this.editEquipForm.patchValue({
      filterBrandForFuel: '',
      filterProductForFuel: '',
      filterBrandForFuelNotListed: false,
      filterProductForFuelNotListed: false
    });
  }

  enableFluidOil() {
    this.editEquipForm.controls['fluidBrandForOil'].enable();
    this.editEquipForm.controls['fluidProductForOil'].enable();
    this.editEquipForm.controls['fluidGradeForOil'].enable();

    this.editEquipForm.controls['fluidBrandForOilNotListed'].enable();
    this.editEquipForm.controls['fluidProductForOilNotListed'].enable();
    this.editEquipForm.controls['fluidGradeForOilNotListed'].enable();
  }

  enableFluidCoolant() {
    this.editEquipForm.controls['fluidBrandForCoolant'].enable();
    this.editEquipForm.controls['fluidProductForCoolant'].enable();
    this.editEquipForm.controls['fluidGradeForCoolant'].enable();
    this.editEquipForm.controls['fluidBrandForCoolantNotListed'].enable();
    this.editEquipForm.controls['fluidProductForCoolantNotListed'].enable();
    this.editEquipForm.controls['fluidGradeForCoolantNotListed'].enable();
  }

  enableFluidFuel() {
    this.editEquipForm.controls['fluidBrandForFuel'].enable();
    this.editEquipForm.controls['fluidProductForFuel'].enable();
    this.editEquipForm.controls['fluidGradeForFuel'].enable();
    this.editEquipForm.controls['fluidBrandForFuelNotListed'].enable();
    this.editEquipForm.controls['fluidProductForFuelNotListed'].enable();
    this.editEquipForm.controls['fluidGradeForFuelNotListed'].enable();
  }

  enableFluidDef() {
    this.editEquipForm.controls['fluidBrandForDef'].enable();
    this.editEquipForm.controls['fluidProductForDef'].enable();
    this.editEquipForm.controls['fluidGradeForDef'].enable();
    this.editEquipForm.controls['fluidBrandForDefNotListed'].enable();
    this.editEquipForm.controls['fluidProductForDefNotListed'].enable();
    this.editEquipForm.controls['fluidGradeForDefNotListed'].enable();
  }

  enableFilterOil() {
    this.editEquipForm.controls['filterBrandForOil'].enable();
    this.editEquipForm.controls['filterProductForOil'].enable();
    this.editEquipForm.controls['filterBrandForOilNotListed'].enable();
    this.editEquipForm.controls['filterProductForOilNotListed'].enable();
  }

  enableFilterCoolant() {
    this.editEquipForm.controls['filterBrandForCoolant'].enable();
    this.editEquipForm.controls['filterProductForCoolant'].enable();
    this.editEquipForm.controls['filterBrandForCoolantNotListed'].enable();
    this.editEquipForm.controls['filterProductForCoolantNotListed'].enable();
  }

  enableFilterFuel() {
    this.editEquipForm.controls['filterBrandForFuel'].enable();
    this.editEquipForm.controls['filterProductForFuel'].enable();
    this.editEquipForm.controls['filterBrandForFuelNotListed'].enable();
    this.editEquipForm.controls['filterProductForFuelNotListed'].enable();
  }

  disableFluidOil() {
    this.editEquipForm.controls['fluidBrandForOil'].disable();
    this.editEquipForm.controls['fluidProductForOil'].disable();
    this.editEquipForm.controls['fluidGradeForOil'].disable();

    this.editEquipForm.controls['fluidBrandForOilNotListed'].disable();
    this.editEquipForm.controls['fluidProductForOilNotListed'].disable();
    this.editEquipForm.controls['fluidGradeForOilNotListed'].disable();
  }

  disableFluidCoolant() {
    this.editEquipForm.controls['fluidBrandForCoolant'].disable();
    this.editEquipForm.controls['fluidProductForCoolant'].disable();
    this.editEquipForm.controls['fluidGradeForCoolant'].disable();
    this.editEquipForm.controls['fluidBrandForCoolantNotListed'].disable();
    this.editEquipForm.controls['fluidProductForCoolantNotListed'].disable();
    this.editEquipForm.controls['fluidGradeForCoolantNotListed'].disable();
  }

  disableFluidFuel() {
    this.editEquipForm.controls['fluidBrandForFuel'].disable();
    this.editEquipForm.controls['fluidProductForFuel'].disable();
    this.editEquipForm.controls['fluidGradeForFuel'].disable();
    this.editEquipForm.controls['fluidBrandForFuelNotListed'].disable();
    this.editEquipForm.controls['fluidProductForFuelNotListed'].disable();
    this.editEquipForm.controls['fluidGradeForFuelNotListed'].disable();
  }

  disableFluidDef() {
    this.editEquipForm.controls['fluidBrandForDef'].disable();
    this.editEquipForm.controls['fluidProductForDef'].disable();
    this.editEquipForm.controls['fluidGradeForDef'].disable();
    this.editEquipForm.controls['fluidBrandForDefNotListed'].disable();
    this.editEquipForm.controls['fluidProductForDefNotListed'].disable();
    this.editEquipForm.controls['fluidGradeForDefNotListed'].disable();
  }

  disableFilterOil() {
    this.editEquipForm.controls['filterBrandForOil'].disable();
    this.editEquipForm.controls['filterProductForOil'].disable();
    this.editEquipForm.controls['filterBrandForOilNotListed'].disable();
    this.editEquipForm.controls['filterProductForOilNotListed'].disable();
  }

  disableFilterCoolant() {
    this.editEquipForm.controls['filterBrandForCoolant'].disable();
    this.editEquipForm.controls['filterProductForCoolant'].disable();
    this.editEquipForm.controls['filterBrandForCoolantNotListed'].disable();
    this.editEquipForm.controls['filterProductForCoolantNotListed'].disable();
  }

  disableFilterFuel() {
    this.editEquipForm.controls['filterBrandForFuel'].disable();
    this.editEquipForm.controls['filterProductForFuel'].disable();
    this.editEquipForm.controls['filterBrandForFuelNotListed'].disable();
    this.editEquipForm.controls['filterProductForFuelNotListed'].disable();
  }


  dropdownForOil(fieldName) {
    console.log(fieldName);

    let result = [];
    this.fluidBrandForOilList = [];
    this.fluidProductForOilList = [];
    this.fluidGradeForOilList = [];

    this.fluidCombinationForOilList.forEach((row) => {
      if (this.editEquipForm.controls.fluidBrandForOil.value == '' || this.editEquipForm.controls.fluidBrandForOil.value == 'Data Not Listed' ||
        this.editEquipForm.controls.fluidBrandForOil.value == row.fluidBrand) {
        if (this.editEquipForm.controls.fluidProductForOil.value == '' || this.editEquipForm.controls.fluidProductForOil.value == 'Data Not Listed' ||
          this.editEquipForm.controls.fluidProductForOil.value == row.fluidProduct) {
          if (this.editEquipForm.controls.fluidGradeForOil.value == '' || this.editEquipForm.controls.fluidGradeForOil.value == 'Data Not Listed' ||
            this.editEquipForm.controls.fluidGradeForOil.value == row.fluidGrade) {
            result.push(row);
          }
        }
      }
    });

    result.forEach((row) => {
      this.fluidBrandForOilList.push(row.fluidBrand);
      this.fluidProductForOilList.push(row.fluidProduct);
      this.fluidGradeForOilList.push(row.fluidGrade);
    });
    this.fluidBrandForOilList = Array.from(new Set(this.fluidBrandForOilList));
    this.fluidProductForOilList = Array.from(new Set(this.fluidProductForOilList));
    this.fluidGradeForOilList = Array.from(new Set(this.fluidGradeForOilList));


    this.fluidProductForOilListArr = this.fluidProductForOilList;

    if (fieldName != 'none') {
      this.editEquipForm.controls[fieldName].disable();
      this.editEquipForm.controls[fieldName + 'NotListed'].disable();
    }

    if (this.fluidBrandForOilList.length == 0 || this.fluidProductForOilList.length == 0 || this.fluidGradeForOilList.length == 0) {
      if (fieldName == 'fluidBrandForOil') {
        this.editEquipForm.patchValue({
          fluidProductForOil: '',
          fluidGradeForOil: ''
        });
      } else if (fieldName == 'fluidProductForOil') {
        this.editEquipForm.patchValue({
          fluidBrandForOil: '',
          fluidGradeForOil: ''
        });
      } else if (fieldName == 'fluidGradeForOil') {
        this.editEquipForm.patchValue({
          fluidBrandForOil: '',
          fluidProductForOil: ''
        });
      }
      this.dropdownForOil('none');
    }
  }



  dropdownForCoolant(fieldName) {
    let result = [];
    this.fluidBrandForCoolantList = [];
    this.fluidProductForCoolantList = [];
    this.fluidGradeForCoolantList = [];

    this.fluidCombinationForCoolantList.forEach((row) => {
      if (this.editEquipForm.controls.fluidBrandForCoolant.value == '' || this.editEquipForm.controls.fluidBrandForCoolant.value == 'Data Not Listed' ||
        this.editEquipForm.controls.fluidBrandForCoolant.value == row.fluidBrand) {
        if (this.editEquipForm.controls.fluidProductForCoolant.value == '' || this.editEquipForm.controls.fluidProductForCoolant.value == 'Data Not Listed' ||
          this.editEquipForm.controls.fluidProductForCoolant.value == row.fluidProduct) {
          if (this.editEquipForm.controls.fluidGradeForCoolant.value == '' || this.editEquipForm.controls.fluidGradeForCoolant.value == 'Data Not Listed' ||
            this.editEquipForm.controls.fluidGradeForCoolant.value == row.fluidGrade) {
            result.push(row);
          }
        }
      }
    });

    result.forEach((row) => {
      this.fluidBrandForCoolantList.push(row.fluidBrand);
      this.fluidProductForCoolantList.push(row.fluidProduct);
      this.fluidGradeForCoolantList.push(row.fluidGrade);
    });
    this.fluidBrandForCoolantList = Array.from(new Set(this.fluidBrandForCoolantList));
    this.fluidProductForCoolantList = Array.from(new Set(this.fluidProductForCoolantList));
    this.fluidGradeForCoolantList = Array.from(new Set(this.fluidGradeForCoolantList));

    this.fluidProductForCoolantListArr = this.fluidProductForCoolantList;

    if (fieldName != 'none') {
      this.editEquipForm.controls[fieldName].disable();
      this.editEquipForm.controls[fieldName + 'NotListed'].disable();
    }

    if (this.fluidBrandForCoolantList.length == 0 || this.fluidProductForCoolantList.length == 0 || this.fluidGradeForCoolantList.length == 0) {
      if (fieldName == 'fluidBrandForCoolant') {
        this.editEquipForm.patchValue({
          fluidProductForCoolant: '',
          fluidGradeForCoolant: ''
        });
      } else if (fieldName == 'fluidProductForCoolant') {
        this.editEquipForm.patchValue({
          fluidBrandForCoolant: '',
          fluidGradeForCoolant: ''
        });
      } else if (fieldName == 'fluidGradeForCoolant') {
        this.editEquipForm.patchValue({
          fluidBrandForCoolant: '',
          fluidProductForCoolant: ''
        });
      }
      this.dropdownForCoolant('none');
    }
  }

  dropdownForFuel(fieldName) {
    let result = [];
    this.fluidBrandForFuelList = [];
    this.fluidProductForFuelList = [];
    this.fluidGradeForFuelList = [];

    this.fluidCombinationForFuelList.forEach((row) => {
      if (this.editEquipForm.controls.fluidBrandForFuel.value == '' || this.editEquipForm.controls.fluidBrandForFuel.value == 'Data Not Listed' ||
        this.editEquipForm.controls.fluidBrandForFuel.value == row.fluidBrand) {
        if (this.editEquipForm.controls.fluidProductForFuel.value == '' || this.editEquipForm.controls.fluidProductForFuel.value == 'Data Not Listed' ||
          this.editEquipForm.controls.fluidProductForFuel.value == row.fluidProduct) {
          if (this.editEquipForm.controls.fluidGradeForFuel.value == '' || this.editEquipForm.controls.fluidGradeForFuel.value == 'Data Not Listed' ||
            this.editEquipForm.controls.fluidGradeForFuel.value == row.fluidGrade) {
            result.push(row);
          }
        }
      }
    });

    result.forEach((row) => {
      this.fluidBrandForFuelList.push(row.fluidBrand);
      this.fluidProductForFuelList.push(row.fluidProduct);
      this.fluidGradeForFuelList.push(row.fluidGrade);
    });
    this.fluidBrandForFuelList = Array.from(new Set(this.fluidBrandForFuelList));
    this.fluidProductForFuelList = Array.from(new Set(this.fluidProductForFuelList));
    this.fluidGradeForFuelList = Array.from(new Set(this.fluidGradeForFuelList));

    if (fieldName != 'none') {
      this.editEquipForm.controls[fieldName].disable();
      this.editEquipForm.controls[fieldName + 'NotListed'].disable();
    }

    if (this.fluidBrandForFuelList.length == 0 || this.fluidProductForFuelList.length == 0 || this.fluidGradeForFuelList.length == 0) {
      if (fieldName == 'fluidBrandForFuel') {
        this.editEquipForm.patchValue({
          fluidProductForFuel: '',
          fluidGradeForFuel: ''
        });
      } else if (fieldName == 'fluidProductForFuel') {
        this.editEquipForm.patchValue({
          fluidBrandForFuel: '',
          fluidGradeForFuel: ''
        });
      } else if (fieldName == 'fluidGradeForFuel') {
        this.editEquipForm.patchValue({
          fluidBrandForFuel: '',
          fluidProductForFuel: ''
        });
      }
      this.dropdownForFuel('none');
    }
  }

  dropdownForDef(fieldName) {
    let result = [];
    this.fluidBrandForDefList = [];
    this.fluidProductForDefList = [];
    this.fluidGradeForDefList = [];

    this.fluidCombinationForDefList.forEach((row) => {
      if (this.editEquipForm.controls.fluidBrandForDef.value == '' || this.editEquipForm.controls.fluidBrandForDef.value == 'Data Not Listed' ||
        this.editEquipForm.controls.fluidBrandForDef.value == row.fluidBrand) {
        if (this.editEquipForm.controls.fluidProductForDef.value == '' || this.editEquipForm.controls.fluidProductForDef.value == 'Data Not Listed' ||
          this.editEquipForm.controls.fluidProductForDef.value == row.fluidProduct) {
          if (this.editEquipForm.controls.fluidGradeForDef.value == '' || this.editEquipForm.controls.fluidGradeForDef.value == 'Data Not Listed' ||
            this.editEquipForm.controls.fluidGradeForDef.value == row.fluidGrade) {
            result.push(row);
          }
        }
      }
    });

    result.forEach((row) => {
      this.fluidBrandForDefList.push(row.fluidBrand);
      this.fluidProductForDefList.push(row.fluidProduct);
      this.fluidGradeForDefList.push(row.fluidGrade);
    });
    this.fluidBrandForDefList = Array.from(new Set(this.fluidBrandForDefList));
    this.fluidProductForDefList = Array.from(new Set(this.fluidProductForDefList));
    this.fluidGradeForDefList = Array.from(new Set(this.fluidGradeForDefList));

    this.fluidProductForDefListArr = this.fluidProductForDefList;

    if (fieldName != 'none') {
      this.editEquipForm.controls[fieldName].disable();
      this.editEquipForm.controls[fieldName + 'NotListed'].disable();
    }

    if (this.fluidBrandForDefList.length == 0 || this.fluidProductForDefList.length == 0 || this.fluidGradeForDefList.length == 0) {
      if (fieldName == 'fluidBrandForDef') {
        this.editEquipForm.patchValue({
          fluidProductForDef: '',
          fluidGradeForDef: ''
        });
      } else if (fieldName == 'fluidProductForDef') {
        this.editEquipForm.patchValue({
          fluidBrandForDef: '',
          fluidGradeForDef: ''
        });
      } else if (fieldName == 'fluidGradeForDef') {
        this.editEquipForm.patchValue({
          fluidBrandForDef: '',
          fluidProductForDef: ''
        });
      }
      this.dropdownForDef('none');
    }
  }

  dropdownForEngine(fieldName) {
    let result = [];
    this.engineMakeListArray = [];
    this.engineModelListArray = [];

    this.engMakeModelList.forEach((row) => {
      if (this.editEquipForm.controls.engineMake.value == '' || this.editEquipForm.controls.engineMake.value == 'Data Not Listed' ||
        this.editEquipForm.controls.engineMake.value == row.key) {
        if (this.editEquipForm.controls.engineModel.value == '' || this.editEquipForm.controls.engineModel.value == 'Data Not Listed' ||
          this.editEquipForm.controls.engineModel.value == row.value) {

          result.push(row);
        }

      }
    });


    result.forEach((row) => {
      this.engineMakeListArray.push(row.key);
      this.engineModelListArray.push(row.value);

    });
    this.engineMakeListArray = Array.from(new Set(this.engineMakeListArray));
    this.engineModelListArray = Array.from(new Set(this.engineModelListArray));

    if (fieldName != 'none') {
      //this.editEquipForm.controls[fieldName].disable();
      this.editEquipForm.controls[fieldName + 'NotListed'].disable();
    }

    if (this.engineMakeListArray.length == 0 || this.engineModelListArray.length == 0) {
      if (fieldName == 'engineMake') {
        this.editEquipForm.patchValue({
          engineModel: ''

        });
      } else if (fieldName == 'engineModel') {
        this.editEquipForm.patchValue({
          engineMake: ''
        });
      }
      this.dropdownForEngine('none');
    }
  }
  OnSaveComponent(){ 
    console.log("this.newArr : ", this.newArr);
    if(this.newArr.length > 0) {
      if(this.editComponentClicked) {
        let checkArr = [];
        this.newArr.forEach((_value,i) => {
          if(Number(i) !== Number(this.editComponentIndex) && (this.newArr[i].componentName == this.selectedTab)) {
            checkArr.push(this.newArr[i]);
          }
        });
        const filterSecondaryCheck = checkArr.filter(x => x.secondaryId == this.editEquipForm.controls.secondaryUnitId.value);
        if(filterSecondaryCheck.length > 0) {
          this.scrollToTop = true;
          this.alertService.error("The Secondary ID entered already exists. Please enter a unique value.");
          return false;
        }  
      } else {
        const filterSecondaryCheck = this.newArr.filter(x => (x.secondaryId == this.editEquipForm.controls.secondaryUnitId.value) && (x.componentName == this.selectedTab));
        if(filterSecondaryCheck.length > 0) {
          this.scrollToTop = true;
          this.alertService.error("The Secondary ID entered already exists. Please enter a unique value.");
          return false;
        }      
      }
    }  
    this.showGrid = true;   
    const abbrName = this.componentTypeList.filter(row => {
      return (row.componentType == this.selectedTab);
    }); 
    // const abbrNumber = this.newArr.filter(row => {
    //   return (row.componentName == this.selectedTab);
    // }); 
    // let newAbbrNo;
    // if(!this.editComponentClicked){
    //   newAbbrNo = Number(abbrNumber.length + 1); 
    // } else {
    //   newAbbrNo = this.newArr[this.editComponentIndex].componentIndex;
    // }
    const secondaryIdNew =  (this.editEquipForm.controls["secondaryUnitId"].value != '')? '-' + this.editEquipForm.controls["secondaryUnitId"].value: '';
    this.componentIdNew = '';
    this.componentIdNew = this.editEquipForm.controls["unitId"].value + '-' + abbrName[0].abbreviation + secondaryIdNew;  
    console.log('this.editEquipForm.controls["unitId"].value : ', this.editEquipForm.controls["unitId"].value); 
    console.log("abbrName[0].abbreviation : ", abbrName[0].abbreviation);
    console.log("secondaryIdNew : ", secondaryIdNew);
    console.log("this.componentIdNew : ", this.componentIdNew);
    console.log("this.editEquipForm.controls : ", this.editEquipForm.controls);
    const engineMakeAddNewName = (this.editEquipForm.controls["engineMakeNotListed"].value == true)? this.editEquipForm.controls["engineMakeName"].value : this.editEquipForm.controls["engineMake"].value;
    const engineModelAddNewName = (this.editEquipForm.controls["engineModelNotListed"].value == true)? this.editEquipForm.controls["engineModelName"].value : this.editEquipForm.controls["engineModel"].value;
    let dataArr1 = {
      'Make': (this.editEquipForm.controls.componentMake.value !== '')?this.editEquipForm.controls.componentMake.value: engineMakeAddNewName,
      'Model': (this.editEquipForm.controls.componentModel.value !== '')?this.editEquipForm.controls.componentModel.value:engineModelAddNewName,
      'Year': (this.editEquipForm.controls.componentYear.value !== '')?this.editEquipForm.controls.componentYear.value:this.editEquipForm.controls.engineYear.value,
      'componentName':  this.selectedTab,
      'componentId':this.componentIdNew,
      'unitMeasure': this.editEquipForm.controls.distanceUom.value,
      'secondaryId': this.editEquipForm.controls.secondaryUnitId.value,
      'serialNo': this.editEquipForm.controls.serialNo.value,

      'fluidTypeForOil': this.editEquipForm.controls.fluidTypeForOil.value,
      'fluidBrandForOil': this.editEquipForm.controls.fluidBrandForOil.value,
      'fluidProductForOil': this.editEquipForm.controls.fluidProductForOil.value,       
      'fluidGradeForOil': this.editEquipForm.controls.fluidGradeForOil.value,     
      'filterBrandForOil':this.editEquipForm.controls.filterBrandForOil.value,
      'filterProductForOil':this.editEquipForm.controls.filterProductForOil.value,
      
      'fluidTypeForCoolant': this.editEquipForm.controls.fluidTypeForCoolant.value,
      'fluidBrandForCoolant': this.editEquipForm.controls.fluidBrandForCoolant.value,
      'fluidProductForCoolant': this.editEquipForm.controls.fluidProductForCoolant.value,
      'fluidGradeForCoolant': this.editEquipForm.controls.fluidGradeForCoolant.value,
      'filterBrandForCoolant':this.editEquipForm.controls.filterBrandForCoolant.value,
      'filterProductForCoolant':this.editEquipForm.controls.filterProductForCoolant.value,

      'fluidTypeForFuel': this.editEquipForm.controls.fluidTypeForFuel.value,
      'fluidBrandForFuel': this.editEquipForm.controls.fluidBrandForFuel.value,
      'fluidProductForFuel': this.editEquipForm.controls.fluidProductForFuel.value,
      'fluidGradeForFuel': this.editEquipForm.controls.fluidGradeForFuel.value,
      'filterBrandForFuel':this.editEquipForm.controls.filterBrandForFuel.value,
      'filterProductForFuel':this.editEquipForm.controls.filterProductForFuel.value,
      
      'fluidTypeForDef': this.editEquipForm.controls.fluidTypeForDef.value,
      'fluidBrandForDef': this.editEquipForm.controls.fluidBrandForDef.value,
      'fluidProductForDef': this.editEquipForm.controls.fluidProductForDef.value,
      'fluidGradeForDef': this.editEquipForm.controls.fluidGradeForDef.value,

      'isDefChecked': this.editEquipForm.controls['isDefChecked'].value == true? true: false,
      'isOilChecked': this.editEquipForm.controls['isOilChecked'].value == true? true: false,
      'isCoolantChecked': this.editEquipForm.controls['isCoolantChecked'].value == true? true:  false,
      'isFuelChecked': this.editEquipForm.controls['isFuelChecked'].value == true? true: false,
      'engineMakeNotListed': this.editEquipForm.controls['engineMakeNotListed'].value == true? true:false,
      'engineModelNotListed': this.editEquipForm.controls['engineModelNotListed'].value == true? true:false,
      'equipmentId': ''
    }
    console.log("dataArr1",dataArr1);
    console.log("this.editEquipForm.controls",this.editEquipForm.controls);
    console.log("this.editComponentClicked : ", this.editComponentClicked);
    if(!this.editComponentClicked){
      this.newArr.push(dataArr1); 
    } else {
      if (this.mode == 'edit') {
        console.log("this.selectionComponentIndexValue : ", this.selectionComponentIndexValue);
        const filterSecondary = this.newArr.filter(x => x.componentId == dataArr1.componentId);
      //  if(filterSecondary.length > 0) {
        if(Number(this.selectionComponentIndexValue) > 0) {
          console.log("Inside Edit function replace");
          console.log("this.editComponentIndex : ", this.editComponentIndex);
          console.log("this.newArr : ", this.newArr);
        // if(this.newArr[this.editComponentIndex].equipmentId !== undefined) {
          dataArr1.equipmentId = this.newArr[this.editComponentIndex].equipmentId;
          this.newArr[this.editComponentIndex] = dataArr1;
          this.editComponentClicked = false;
        } else {
          console.log("New Push edit func");
          this.newArr.push(dataArr1);
        }
      } else {
        this.newArr[this.editComponentIndex] = dataArr1;
        this.editComponentClicked = false;
      }
    }
    this.selectedTab = '';
    this.addComponentForm = false;
  }


  notListedChange(fieldName, event) {
    let fieldValue = '';
    if (event.target.checked === true) {
      this.editEquipForm.controls[fieldName].disable();
      fieldValue = 'Data Not Listed';
    } else {
      this.editEquipForm.controls[fieldName].enable();
      fieldValue = '';
    }
    this.editEquipForm.patchValue({
      [fieldName]: fieldValue
    });



  }

  dropdownForFilterOil(fieldName) {
    let result = [];
    this.filterBrandForOilList = [];
    this.filterProductForOilList = [];

    this.filterCombinationForOilList.forEach((row) => {
      if (this.editEquipForm.controls.filterBrandForOil.value == '' || this.editEquipForm.controls.filterBrandForOil.value == 'Data Not Listed' ||
        this.editEquipForm.controls.filterBrandForOil.value == row.filterBrand) {
        if (this.editEquipForm.controls.filterProductForOil.value == '' || this.editEquipForm.controls.filterProductForOil.value == 'Data Not Listed' ||
          this.editEquipForm.controls.filterProductForOil.value == row.filterProduct) {
          result.push(row);
        }
      }
    });

    result.forEach((row) => {
      this.filterBrandForOilList.push(row.filterBrand);
      this.filterProductForOilList.push(row.filterProduct);
    });
    this.filterBrandForOilList = Array.from(new Set(this.filterBrandForOilList));
    this.filterProductForOilList = Array.from(new Set(this.filterProductForOilList));

    this.filterProductForOilListArr = this.filterProductForOilList;

    if (fieldName != 'none') {
      this.editEquipForm.controls[fieldName].disable();
      this.editEquipForm.controls[fieldName + 'NotListed'].disable();
    }

    if (this.filterBrandForOilList.length == 0 || this.filterProductForOilList.length == 0) {
      if (fieldName == 'filterBrandForOil') {
        this.editEquipForm.patchValue({
          filterProductForOil: ''
        });
      } else if (fieldName == 'filterProductForOil') {
        this.editEquipForm.patchValue({
          filterBrandForOil: ''
        });
      }
      this.dropdownForFilterOil('none');
    }
  }

  dropdownForFilterCoolant(fieldName) {
    let result = [];
    this.filterBrandForCoolantList = [];
    this.filterProductForCoolantList = [];

    this.filterCombinationForCoolantList.forEach((row) => {
      if (this.editEquipForm.controls.filterBrandForCoolant.value == '' || this.editEquipForm.controls.filterBrandForCoolant.value == 'Data Not Listed' ||
        this.editEquipForm.controls.filterBrandForCoolant.value == row.filterBrand) {
        if (this.editEquipForm.controls.filterProductForCoolant.value == '' || this.editEquipForm.controls.filterProductForCoolant.value == 'Data Not Listed' ||
          this.editEquipForm.controls.filterProductForCoolant.value == row.filterProduct) {
          result.push(row);
        }
      }
    });

    result.forEach((row) => {
      this.filterBrandForCoolantList.push(row.filterBrand);
      this.filterProductForCoolantList.push(row.filterProduct);
    });
    this.filterBrandForCoolantList = Array.from(new Set(this.filterBrandForCoolantList));
    this.filterProductForCoolantList = Array.from(new Set(this.filterProductForCoolantList));

    this.filterProductForCoolantListArr = this.filterProductForCoolantList;

    if (fieldName != 'none') {
      this.editEquipForm.controls[fieldName].disable();
      this.editEquipForm.controls[fieldName + 'NotListed'].disable();
    }

    if (this.filterBrandForCoolantList.length == 0 || this.filterProductForCoolantList.length == 0) {
      if (fieldName == 'filterBrandForCoolant') {
        this.editEquipForm.patchValue({
          filterProductForCoolant: ''
        });
      } else if (fieldName == 'filterProductForCoolant') {
        this.editEquipForm.patchValue({
          filterBrandForCoolant: ''
        });
      }
      this.dropdownForFilterCoolant('none');
    }
  }

  dropdownForFilterFuel(fieldName) {
    let result = [];
    this.filterBrandForFuelList = [];
    this.filterProductForFuelList = [];

    this.filterCombinationForFuelList.forEach((row) => {
      if (this.editEquipForm.controls.filterBrandForFuel.value == '' || this.editEquipForm.controls.filterBrandForFuel.value == 'Data Not Listed' ||
        this.editEquipForm.controls.filterBrandForFuel.value == row.filterBrand) {
        if (this.editEquipForm.controls.filterProductForFuel.value == '' || this.editEquipForm.controls.filterProductForFuel.value == 'Data Not Listed' ||
          this.editEquipForm.controls.filterProductForFuel.value == row.filterProduct) {
          result.push(row);
        }
      }
    });

    result.forEach((row) => {
      this.filterBrandForFuelList.push(row.filterBrand);
      this.filterProductForFuelList.push(row.filterProduct);
    });
    this.filterBrandForFuelList = Array.from(new Set(this.filterBrandForFuelList));
    this.filterProductForFuelList = Array.from(new Set(this.filterProductForFuelList));

    this.filterProductForFuelListArr = this.filterProductForFuelList;

    if (fieldName != 'none') {
      this.editEquipForm.controls[fieldName].disable();
      this.editEquipForm.controls[fieldName + 'NotListed'].disable();
    }

    if (this.filterBrandForFuelList.length == 0 || this.filterProductForFuelList.length == 0) {
      if (fieldName == 'filterBrandForFuel') {
        this.editEquipForm.patchValue({
          filterProductForFuel: ''
        });
      } else if (fieldName == 'filterProductForFuel') {
        this.editEquipForm.patchValue({
          filterBrandForFuel: ''
        });
      }
      this.dropdownForFilterFuel('none');
    }
  }

 
  initialDropdownValuesFluidOil() {
    this.fluidBrandForOilList = this.dropdownOil.fluidBrand;
    this.fluidBrandForOilListArr = this.fluidBrandForOilList;
    this.oilFluidBrand = '';
    this.fluidProductForOilList = this.dropdownOil.fluidProduct;
    this.fluidProductForOilListArr = this.fluidProductForOilList;
    this.oilFluidProduct = '';
    this.fluidGradeForOilList = this.dropdownOil.fluidGrade;
  }

  initialDropdownValuesFluidCoolant() {
    this.fluidBrandForCoolantList = this.dropdownCoolant.fluidBrand;
    this.fluidBrandForCoolantListArr = this.fluidBrandForCoolantList;
    this.coolantFluidBrand = '';
    this.fluidProductForCoolantList = this.dropdownCoolant.fluidProduct;
    this.fluidProductForCoolantListArr = this.fluidProductForCoolantList;
    this.coolantFluidProduct = '';
    this.fluidGradeForCoolantList = this.dropdownCoolant.fluidGrade;
  }

  initialDropdownValuesFluidFuel() {
    this.fluidBrandForFuelList = this.dropdownFuel.fluidBrand;
    this.fluidProductForFuelList = this.dropdownFuel.fluidProduct;
    this.fluidGradeForFuelList = this.dropdownFuel.fluidGrade;
  }

  initialDropdownValuesFluidDef() {
    this.fluidBrandForDefList = this.dropdownDef.fluidBrand;
    this.fluidBrandForDefListArr = this.fluidBrandForDefList;
    this.defFluidBrand = '';
    this.fluidProductForDefList = this.dropdownDef.fluidProduct;
    this.fluidProductForDefListArr = this.fluidProductForDefList;
    this.defFluidProduct = '';
    this.fluidGradeForDefList = this.dropdownDef.fluidGrade;
  }

  initialDropdownValuesFilterOil() {
    this.filterBrandForOilList = this.dropdownOil.filterBrand;
    this.filterProductForOilList = this.dropdownOil.filterProduct;
    this.filterProductForOilListArr = this.filterProductForOilList;
    this.oilfilterProduct = '';
  }

  initialDropdownValuesFilterCoolant() {
    this.filterBrandForCoolantList = this.dropdownCoolant.filterBrand;
    this.filterProductForCoolantList = this.dropdownCoolant.filterProduct;
    this.filterProductForCoolantListArr = this.filterProductForCoolantList;
    this.coolantFilterProduct = '';
  }

  initialDropdownValuesFilterFuel() {
    this.filterBrandForFuelList = this.dropdownFuel.filterBrand;
    this.filterProductForFuelList = this.dropdownFuel.filterProduct;
    this.filterProductForFuelListArr = this.filterProductForFuelList;
    this.fuelFilterProduct = '';
  }

  setValueFluidOil() {
    this.initialDropdownValuesFluidOil();
    if (this.mode == 'add') {
      this.clearFluidOil();
      this.enableFluidOil();
    } else if (this.mode == 'edit') {
      if (this.individualData.fluidForOil.equipId != '') {
        this.clearFluidOil();
        setTimeout(() => {
          this.enableFluidOil();
          this.editEquipForm.patchValue({
            isOilChecked: true,
            //fluidTypeForOil : this.fluidType['Oil'],          
            fluidBrandForOil: this.individualData.fluidForOil.fluidBrand,
            fluidProductForOil: this.individualData.fluidForOil.fluidProduct,
            fluidGradeForOil: this.individualData.fluidForOil.fluidGrade,
          });
        }, 100)
        

        //changes to handle Natural gas
        if (this.editEquipForm.controls.engineType.value == 1) {
          this.editEquipForm.patchValue({
            fluidTypeForOil: this.fluidType['Oil']
          });
        } else if (this.editEquipForm.controls.engineType.value == 2) {
          this.editEquipForm.patchValue({
            fluidTypeForOil: this.fluidType['NatGas']
          });
        }

        let dropdownFields = ['fluidBrandForOil', 'fluidProductForOil', 'fluidGradeForOil'];
        dropdownFields.forEach((fieldName) => {
          if (this.editEquipForm.controls[fieldName].value == 'Data Not Listed') {
            this.editEquipForm.controls[fieldName].disable();
            this.editEquipForm.controls[fieldName + 'NotListed'].enable();
            this.editEquipForm.patchValue({
              [fieldName + 'NotListed']: true
            });
          } else if (this.editEquipForm.controls[fieldName].value != '') {
            this.editEquipForm.controls[fieldName].enable();
            this.editEquipForm.controls[fieldName + 'NotListed'].enable();
            this.editEquipForm.patchValue({
              [fieldName + 'NotListed']: false
            });
          }
        });
      } else {
        this.clearFluidOil();
        setTimeout(() => {
          if (this.mode == 'edit') {
            this.clearFluidOil();
            this.editEquipForm.patchValue({
              isOilChecked: false
            });
            this.fluidTypeChange('Oil', { target: { checked: false } });
          }
        }, 100);
        if (this.editEquipForm.controls['isOilChecked'].value == true) {
          this.enableFluidOil();
        } else {
          this.disableFluidOil();
        }
        //this.disableFluidOil();
      }
    }

  }

  setValueFluidCoolant() {
    this.initialDropdownValuesFluidCoolant();
    if (this.mode == 'add') {
      this.clearFluidCoolant();
      this.enableFluidCoolant();
    } else if (this.mode == 'edit') {
      if (this.individualData.fluidForCoolant.equipId != '') {
        this.clearFluidCoolant();
        setTimeout(() => {
          this.enableFluidCoolant();
          this.editEquipForm.patchValue({
            isCoolantChecked: true,
            fluidTypeForCoolant: this.fluidType['Coolant'],
            fluidBrandForCoolant: this.individualData.fluidForCoolant.fluidBrand,
            fluidProductForCoolant: this.individualData.fluidForCoolant.fluidProduct,
            fluidGradeForCoolant: this.individualData.fluidForCoolant.fluidGrade,
          });
        }, 100);

        let dropdownFields = ['fluidBrandForCoolant', 'fluidProductForCoolant', 'fluidGradeForCoolant'];
        dropdownFields.forEach((fieldName) => {
          if (this.editEquipForm.controls[fieldName].value == 'Data Not Listed') {
            this.editEquipForm.controls[fieldName].disable();
            this.editEquipForm.controls[fieldName + 'NotListed'].enable();
            this.editEquipForm.patchValue({
              [fieldName + 'NotListed']: true
            });
          } else if (this.editEquipForm.controls[fieldName].value != '') {
            this.editEquipForm.controls[fieldName].enable();
            this.editEquipForm.controls[fieldName + 'NotListed'].enable();
            this.editEquipForm.patchValue({
              [fieldName + 'NotListed']: false
            });
          }
        });
      } else {
        this.clearFluidCoolant();
        setTimeout(() => {
          if (this.mode == 'edit') {
            this.clearFluidCoolant();
            this.editEquipForm.patchValue({
              isCoolantChecked: false
            });
            this.fluidTypeChange('Coolant', { target: { checked: false } });
          }
        }, 100);
        if (this.editEquipForm.controls['isCoolantChecked'].value == true) {
          this.enableFluidCoolant();
        } else {
          this.disableFluidCoolant();
        }
        //this.disableFluidCoolant();
      }
    }
  }

  setValueFluidFuel() {
    this.initialDropdownValuesFluidFuel();
    if (this.mode == 'add') {
      this.clearFluidFuel();
      this.enableFluidFuel();
    } else if (this.mode == 'edit') {
      if (this.individualData.fluidForFuel.equipId != '') {
        this.clearFluidFuel();
        setTimeout(() => {
          this.enableFluidFuel();
          this.editEquipForm.patchValue({
            isFuelChecked: true,
            fluidTypeForFuel: this.fluidType['Fuel'],
            fluidBrandForFuel: this.individualData.fluidForFuel.fluidBrand,
            fluidProductForFuel: this.individualData.fluidForFuel.fluidProduct,
            fluidGradeForFuel: this.individualData.fluidForFuel.fluidGrade,
          });
        }, 100);

        let dropdownFields = ['fluidBrandForFuel', 'fluidProductForFuel', 'fluidGradeForFuel'];
        dropdownFields.forEach((fieldName) => {
          if (this.editEquipForm.controls[fieldName].value == 'Data Not Listed') {
            this.editEquipForm.controls[fieldName].disable();
            this.editEquipForm.controls[fieldName + 'NotListed'].enable();
            this.editEquipForm.patchValue({
              [fieldName + 'NotListed']: true
            });
          } else if (this.editEquipForm.controls[fieldName].value != '') {
            this.editEquipForm.controls[fieldName].enable();
            this.editEquipForm.controls[fieldName + 'NotListed'].enable();
            this.editEquipForm.patchValue({
              [fieldName + 'NotListed']: false
            });
          }
        });
      } else {
        this.clearFluidFuel();
        setTimeout(() => {
          if (this.mode == 'edit') {
            this.clearFluidFuel();
            this.editEquipForm.patchValue({
              isFuelChecked: false
            });
            this.fluidTypeChange('Fuel', { target: { checked: false } });
          }
        }, 100);
        if (this.editEquipForm.controls['isFuelChecked'].value == true) {
          this.enableFluidFuel();
        } else {
          this.disableFluidFuel();
        }
        //this.disableFluidFuel();
      }
    }
  }

  setValueFluidDef() {
    this.initialDropdownValuesFluidDef();
    if (this.mode == 'add') {
      this.clearFluidDef();
      this.enableFluidDef();
    } else if (this.mode == 'edit') {
      if (this.individualData.fluidForDEF.equipId != '') {
        this.clearFluidDef();
        setTimeout(() => {
          this.enableFluidDef();
          this.editEquipForm.patchValue({
            isDefChecked: true,
            fluidTypeForDef: this.fluidType['Def'],
            fluidBrandForDef: this.individualData.fluidForDEF.fluidBrand,
            fluidProductForDef: this.individualData.fluidForDEF.fluidProduct,
            fluidGradeForDef: this.individualData.fluidForDEF.fluidGrade,
          });
        }, 100);

        let dropdownFields = ['fluidBrandForDef', 'fluidProductForDef', 'fluidGradeForDef'];
        dropdownFields.forEach((fieldName) => {
          if (this.editEquipForm.controls[fieldName].value == 'Data Not Listed') {
            this.editEquipForm.controls[fieldName].disable();
            this.editEquipForm.controls[fieldName + 'NotListed'].enable();
            this.editEquipForm.patchValue({
              [fieldName + 'NotListed']: true
            });
          } else if (this.editEquipForm.controls[fieldName].value != '') {
            this.editEquipForm.controls[fieldName].enable();
            this.editEquipForm.controls[fieldName + 'NotListed'].enable();
            this.editEquipForm.patchValue({
              [fieldName + 'NotListed']: false
            });
          }
        });
      } else {
        this.clearFluidDef();
        setTimeout(() => {
          if (this.mode == 'edit') {
            this.clearFluidDef();
            this.editEquipForm.patchValue({
              isDefChecked: false
            });
            this.fluidTypeChange('Def', { target: { checked: false } });
          }
        }, 100);
        if (this.editEquipForm.controls['isDefChecked'].value == true) {
          this.enableFluidDef();
        } else {
          this.disableFluidDef();
        }
        //this.disableFluidDef();
      }
    }
  }

  setValueFilterOil() {
    this.initialDropdownValuesFilterOil();
    if (this.mode == 'add') {
      this.clearFilterOil();
      this.enableFilterOil();
    } else if (this.mode == 'edit') {
      if (this.individualData.filterForOil.equipId != '') {
        this.clearFilterOil();
        setTimeout(() => {
          this.enableFilterOil();
          this.editEquipForm.patchValue({
            filterBrandForOil: this.individualData.filterForOil.filterBrand,
            filterProductForOil: this.individualData.filterForOil.filterProduct
          });
        }, 100)

        let dropdownFields = ['filterBrandForOil', 'filterProductForOil'];
        dropdownFields.forEach((fieldName) => {
          if (this.editEquipForm.controls[fieldName].value == 'Data Not Listed') {
            this.editEquipForm.controls[fieldName].disable();
            this.editEquipForm.controls[fieldName + 'NotListed'].enable();
            this.editEquipForm.patchValue({
              [fieldName + 'NotListed']: true
            });
          } else if (this.editEquipForm.controls[fieldName].value != '') {
            this.editEquipForm.controls[fieldName].enable();
            this.editEquipForm.controls[fieldName + 'NotListed'].enable();
            this.editEquipForm.patchValue({
              [fieldName + 'NotListed']: false
            });
          }
        });
      } else {
        this.clearFilterOil();
        setTimeout(() => {
          if (this.mode == 'edit') {
            this.clearFilterOil();
            this.editEquipForm.patchValue({
              isOilChecked: false
            });
            this.fluidTypeChange('Oil', { target: { checked: false } });
          }
        }, 100);
        if (this.editEquipForm.controls['isOilChecked'].value == true) {
          this.enableFilterOil();
        } else {
          this.disableFilterOil();
        }
        //this.disableFilterOil();
      }
    }
  }

  setValueFilterCoolant() {
    this.initialDropdownValuesFilterCoolant();
    if (this.mode == 'add') {
      this.clearFilterCoolant();
      this.enableFilterCoolant();
    } else if (this.mode == 'edit') {
      if (this.individualData.filterForCoolant.equipId != '') {
        this.clearFilterCoolant();
        setTimeout(() => {
          this.enableFilterCoolant();
          this.editEquipForm.patchValue({
            filterBrandForCoolant: this.individualData.filterForCoolant.filterBrand,
            filterProductForCoolant: this.individualData.filterForCoolant.filterProduct
          });
        }, 100);

        let dropdownFields = ['filterBrandForCoolant', 'filterProductForCoolant'];
        dropdownFields.forEach((fieldName) => {
          if (this.editEquipForm.controls[fieldName].value == 'Data Not Listed') {
            this.editEquipForm.controls[fieldName].disable();
            this.editEquipForm.controls[fieldName + 'NotListed'].enable();
            this.editEquipForm.patchValue({
              [fieldName + 'NotListed']: true
            });
          } else if (this.editEquipForm.controls[fieldName].value != '') {
            this.editEquipForm.controls[fieldName].enable();
            this.editEquipForm.controls[fieldName + 'NotListed'].enable();
            this.editEquipForm.patchValue({
              [fieldName + 'NotListed']: false
            });
          }
        });
      } else {
        this.clearFilterCoolant();
        setTimeout(() => {
          if (this.mode == 'edit') {
            this.clearFilterCoolant();
            this.editEquipForm.patchValue({
              isCoolantChecked: false
            });
            this.fluidTypeChange('Coolant', { target: { checked: false } });
          }
        }, 100);
        if (this.editEquipForm.controls['isCoolantChecked'].value == true) {
          this.enableFilterCoolant();
        } else {
          this.disableFilterCoolant();
        }
        //this.disableFilterCoolant();
      }
    }
  }

  setValueFilterFuel() {
    this.initialDropdownValuesFilterFuel();
    if (this.mode == 'add') {
      this.clearFilterFuel();
      this.enableFilterFuel();
    } else if (this.mode == 'edit') {
      if (this.individualData.filterForFuel.equipId != '') {
        this.clearFilterFuel();
        setTimeout(() => {
          this.enableFilterFuel();
          this.editEquipForm.patchValue({
            filterBrandForFuel: this.individualData.filterForFuel.filterBrand,
            filterProductForFuel: this.individualData.filterForFuel.filterProduct
          });
        }, 100);

        let dropdownFields = ['filterBrandForFuel', 'filterProductForFuel'];
        dropdownFields.forEach((fieldName) => {
          if (this.editEquipForm.controls[fieldName].value == 'Data Not Listed') {
            this.editEquipForm.controls[fieldName].disable();
            this.editEquipForm.controls[fieldName + 'NotListed'].enable();
            this.editEquipForm.patchValue({
              [fieldName + 'NotListed']: true
            });
          } else if (this.editEquipForm.controls[fieldName].value != '') {
            this.editEquipForm.controls[fieldName].enable();
            this.editEquipForm.controls[fieldName + 'NotListed'].enable();
            this.editEquipForm.patchValue({
              [fieldName + 'NotListed']: false
            });
          }
        });
      } else {
        this.clearFilterFuel();
        setTimeout(() => {
          if (this.mode == 'edit') {
            this.clearFilterFuel();
            this.editEquipForm.patchValue({
              isFuelChecked: false
            });
            this.fluidTypeChange('Fuel', { target: { checked: false } });
          }
        }, 100);
        if (this.editEquipForm.controls['isFuelChecked'].value == true) {
          this.enableFilterFuel();
        } else {
          this.disableFilterFuel();
        }
        //this.disableFilterFuel();
      }
    }
  }

  trimEmptySpace() {
    this.editEquipForm.patchValue({
      unitId: this.editEquipForm.controls['unitId'].value.trim(),
      equipSerialNo: this.editEquipForm.controls['equipSerialNo'].value.trim(),
      secondaryUnitId: this.editEquipForm.controls['secondaryUnitId'].value.trim(),
     // esn: (this.editEquipForm.controls['esn'] !== undefined)?this.editEquipForm.controls['esn'].value.trim(): '',
      //      unitMake : this.editEquipForm.controls['unitMake'].value.trim(),
      unitModel: this.editEquipForm.controls['unitModel'].value.trim()
    });
  }

  engineTypeChange() {
    this.fetchFluidAndFilter();
    this.clearFluidOil();
    this.clearFilterOil();
  }

  fetchFluidAndFilter() {
    let reqData = {
      "userRole": this.sharedService.getIntOrExtUser()
    }
    if (this.editEquipForm.controls.engineType.value == 1) {
      reqData['fluidType'] = '1';
      this.editEquipForm.patchValue({
        fluidTypeForOil: this.fluidType['Oil']
      });
    } else if (this.editEquipForm.controls.engineType.value == 2) {
      reqData['fluidType'] = '2';
      this.editEquipForm.patchValue({
        fluidTypeForOil: this.fluidType['NatGas']
      });
    }
    this.addEquipServ.getFluidAndFilterDetails(reqData).subscribe(response => {
      console.log(response);

      this.dropdownOil = response;
      this.fluidBrandForOilList = response.fluidBrand;
      this.fluidProductForOilList = response.fluidProduct;
      this.fluidGradeForOilList = response.fluidGrade;
      this.filterBrandForOilList = response.filterBrand;
      this.filterProductForOilList = response.filterProduct;
      this.fluidCombinationForOilList = response.fluidCombination;
      this.filterCombinationForOilList = response.filterCombination;
    });
  }




  //for bulk tank storage
  bulkTankFuel() {
    this.isBulkTankFuelStorage = !this.isBulkTankFuelStorage;
    if (this.isBulkTankFuelStorage) {
      this.editEquipForm.patchValue({
        esn: 'ESN-Bulk Storage',
        unitMake: 'Not Applicable',
        unitModel: 'Not Applicable',
        gsn: 'Not Applicable',
        applicationTypeNotListed: false,
        unitMakeNotListed: false,
        engineMakeNotListed: false,
        engineModelNotListed: false,
        isOilChecked: false,
        isCoolantChecked: false,
        isDefChecked: false,
        fluidTypeForOil: '',
        fluidTypeForCoolant: '',
        fluidTypeForDef: '',
        isFuelChecked: true,
        fluidTypeForFuel: this.fluidType['Fuel'],
        distanceUom: 'MILES',
      });
      this.unitMakeHasError = false,
        this.unitMakeHasListedError = false,
        this.applicationTypeHasError = false,
        this.applicationTypeHasListedError = false,
        this.engineMakeHasError = false,
        this.engineMakeHasListedError = false,
        this.engineModelHasError = false,
        this.engineModelHasListedError = false,
        this.engineModelHasUnknownError = false,
        this.editEquipForm.controls['esn'].disable();
      this.editEquipForm.controls['gsn'].disable();
      this.editEquipForm.controls['applicationTypeNotListed'].disable();
      this.editEquipForm.controls['applicationTypeName'].disable();
      this.editEquipForm.controls['unitMakeNotListed'].disable();
      this.editEquipForm.controls['unitMakeName'].disable();
      this.editEquipForm.controls['engineMakeName'].disable();
      this.editEquipForm.controls['engineModelName'].disable();
      this.editEquipForm.controls['engineMakeNotListed'].disable();
      this.editEquipForm.controls['engineModelNotListed'].disable();
      this.editEquipForm.controls['distanceUom'].disable();
      this.editEquipForm.controls['applicationType'].disable();
      this.editEquipForm.controls['unitMake'].disable();
      this.editEquipForm.controls['unitModel'].disable();
      this.editEquipForm.controls['unitYear'].disable();
      this.editEquipForm.controls['engineMake'].disable();
      this.editEquipForm.controls['engineYear'].disable();
      this.editEquipForm.controls['engineType'].disable();
      this.editEquipForm.controls['isOilChecked'].disable();
      this.editEquipForm.controls['isCoolantChecked'].disable();
      this.editEquipForm.controls['isDefChecked'].disable();
      this.fluidTypeChange('Oil', { target: { checked: false } });
      this.fluidTypeChange('Coolant', { target: { checked: false } });
      this.fluidTypeChange('Def', { target: { checked: false } });
      this.disableFluidOil();
      this.disableFilterOil();
      this.enableFilterFuel();
      this.enableFluidFuel();
    } else {
      this.editEquipForm.patchValue({
        esn: '',
        gsn: '',
        unitMake: '',
        unitModel: '',
        isOilChecked: true,
        fluidTypeForOil: this.fluidType['Oil'],
        isFuelChecked: false,
        fluidTypeForFuel: '',
        distanceUom: ''
      });
      this.editEquipForm.controls['esn'].enable();
      this.editEquipForm.controls['applicationTypeNotListed'].enable();
      this.editEquipForm.controls['applicationTypeName'].enable();
      this.editEquipForm.controls['unitMakeName'].enable();
      this.editEquipForm.controls['engineMakeName'].enable();
      this.editEquipForm.controls['engineModelName'].enable();
      this.editEquipForm.controls['unitMakeNotListed'].enable();
      this.editEquipForm.controls['engineMakeNotListed'].enable();
      this.editEquipForm.controls['gsn'].enable();
      this.editEquipForm.controls['distanceUom'].enable();
      this.editEquipForm.controls['unitMake'].enable();
      this.editEquipForm.controls['applicationType'].enable();
      this.editEquipForm.controls['unitModel'].enable();
      this.editEquipForm.controls['unitYear'].enable();
      this.editEquipForm.controls['engineMake'].enable();
      this.editEquipForm.controls['engineYear'].enable();
      this.editEquipForm.controls['engineType'].enable();
      this.editEquipForm.controls['isOilChecked'].enable();
      this.editEquipForm.controls['isCoolantChecked'].enable();
      this.editEquipForm.controls['isDefChecked'].enable();
      this.enableFluidOil();
      this.enableFilterOil();
      this.disableFilterFuel();
      this.disableFluidFuel();


    }
  }

  onKeyUnitMake(value) {
    this.unitMakeArray = [];
    this.selectSearchUnitMake(value);
    //  console.log("value",value)      
  }

  onKeyFluidBrand(value) {
    console.log(value);

    this.fluidBrandForOilListArr = [];
    this.selectSearchFluidBrand(value);
  }

  onKeyFluidProduct(value) {
    this.fluidProductForOilListArr = [];
    this.selectSearchFluidProduct(value);
  }

  onKeyOilFilterProduct(value) {
    this.filterProductForOilListArr = [];
    this.selectSearchFilterProduct(value);
  }

  onKeyCoolantFluidBrand(value) {
    this.fluidBrandForCoolantListArr = [];
    this.selectSearchCoolantFluidBrand(value);
  }

  onKeyCoolantFluidProduct(value) {
    this.fluidProductForCoolantListArr = [];
    this.selectSearchCoolantFluidProduct(value);
  }

  onKeyCollantFilterProduct(value) {
    this.filterProductForCoolantListArr = [];
    this.selectSearchCoolantFilterProduct(value);
  }

  onKeyFuelFilterProduct(value) {
    this.filterProductForFuelListArr = [];
    this.selectSearchFuelFilterProduct(value);
  }

  onKeyDEFFluidBrand(value) {
    this.fluidBrandForDefListArr = [];
    this.selectSearchDEFFluidBrand(value);
  }

  onKeyDEFFluidProduct(value) {
    this.fluidProductForDefListArr = [];
    this.selectSearchDEFFluidProduct(value);
  }

  onKeyEngineYear(value) {
    this.engineYearListArray = [];
    this.selectSearchEngineYear(value);

  }
  onKeyUnitYear(value) {
    this.unitYearListArray = [];
    this.selectSearchUnitYear(value);
  }

  onKeyEngineMake(value) {
    this.engineMakeListArray = [];
    this.selectSearchEngineMake(value);
  }
  onKeyEngineModel(value) {
    this.engineModelListArray = [];
    this.selectSearchEngineModel(value);

  }


  // onKeyEngineType(value){
  //   this.engineTypeListArray = [];
  //   this.selectSearchEngineType(value);

  // }
  onKeyApplicationType(value) {
    this.applicationTypeListArray = [];
    this.selectSearchApplicationType(value);

  }

  onKeyDistanceUom(value) {
    this.distanceUOMListArray = [];
    this.selectSearchDistanceUom(value);
  }
  selectSearchUnitMake(value: string) {
    let filter = value.toLowerCase();
    for (let i = 0; i < this.unitMakeList.length; i++) {
      let option = this.unitMakeList[i];
      if (option.toLowerCase().indexOf(filter) >= 0) {
        this.unitMakeArray.push(option)
      }
    }

  }
  selectSearchFluidBrand(value: string) {
    let filter = value.toLowerCase();
    for (let i = 0; i < this.fluidBrandForOilList.length; i++) {
      let option = this.fluidBrandForOilList[i];
      if (option.toLowerCase().indexOf(filter) >= 0) {
        this.fluidBrandForOilListArr.push(option)
      }
    }
  }

  selectSearchFluidProduct(value: string) {
    let filter = value.toLowerCase();
    for (let i = 0; i < this.fluidProductForOilList.length; i++) {
      let option = this.fluidProductForOilList[i];
      if (option.toLowerCase().indexOf(filter) >= 0) {
        this.fluidProductForOilListArr.push(option)
      }
    }

  }

  selectSearchFilterProduct(value: string) {
    let filter = value.toLowerCase();
    for (let i = 0; i < this.filterProductForOilList.length; i++) {
      let option = this.filterProductForOilList[i];
      if (option.toLowerCase().indexOf(filter) >= 0) {
        this.filterProductForOilListArr.push(option)
      }
    }
  }

  selectSearchCoolantFluidBrand(value: string) {
    let filter = value.toLowerCase();
    for (let i = 0; i < this.fluidBrandForCoolantList.length; i++) {
      let option = this.fluidBrandForCoolantList[i];
      if (option.toLowerCase().indexOf(filter) >= 0) {
        this.fluidBrandForCoolantListArr.push(option)
      }
    }
  }

  selectSearchCoolantFluidProduct(value: string) {
    let filter = value.toLowerCase();
    for (let i = 0; i < this.fluidProductForCoolantList.length; i++) {
      let option = this.fluidProductForCoolantList[i];
      if (option.toLowerCase().indexOf(filter) >= 0) {
        this.fluidProductForCoolantListArr.push(option)
      }
    }
  }

  selectSearchCoolantFilterProduct(value: string) {
    let filter = value.toLowerCase();
    for (let i = 0; i < this.filterProductForCoolantList.length; i++) {
      let option = this.filterProductForCoolantList[i];
      if (option.toLowerCase().indexOf(filter) >= 0) {
        this.filterProductForCoolantListArr.push(option)
      }
    }
  }

  selectSearchFuelFilterProduct(value: string) {
    let filter = value.toLowerCase();
    for (let i = 0; i < this.filterProductForFuelList.length; i++) {
      let option = this.filterProductForFuelList[i];
      if (option.toLowerCase().indexOf(filter) >= 0) {
        this.filterProductForFuelListArr.push(option)
      }
    }
  }

  selectSearchDEFFluidBrand(value: string) {
    let filter = value.toLowerCase();
    for (let i = 0; i < this.fluidBrandForDefList.length; i++) {
      let option = this.fluidBrandForDefList[i];
      if (option.toLowerCase().indexOf(filter) >= 0) {
        this.fluidBrandForDefListArr.push(option)
      }
    }
  }

  selectSearchDEFFluidProduct(value: string) {
    let filter = value.toLowerCase();
    for (let i = 0; i < this.fluidProductForDefList.length; i++) {
      let option = this.fluidProductForDefList[i];
      if (option.toLowerCase().indexOf(filter) >= 0) {
        this.fluidProductForDefListArr.push(option)
      }
    }
  }

  selectSearchEngineYear(value: string) {
    let filter = value.toLowerCase();
    for (let i = 0; i < this.engineYearList.length; i++) {
      let option = this.engineYearList[i];
      if (option.toLowerCase().indexOf(filter) >= 0) {
        this.engineYearListArray.push(option)
      }
    }
  }
  selectSearchUnitYear(value: string) {
    let filter = value.toLowerCase();
    for (let i = 0; i < this.unitYearList.length; i++) {
      let option = this.unitYearList[i];
      if (option.toLowerCase().indexOf(filter) >= 0) {
        this.unitYearListArray.push(option)
      }
    }
  }

  selectSearchEngineMake(value: string) {
    let filter = value.toLowerCase();
    for (let i = 0; i < this.engineMakeList.length; i++) {
      let option = this.engineMakeList[i];
      if (option.toLowerCase().indexOf(filter) >= 0) {
        this.engineMakeListArray.push(option)
      }
    }
  }

  selectSearchEngineModel(value: string) {
    let filter = value.toLowerCase();
    for (let i = 0; i < this.engineModelList.length; i++) {
      let option = this.engineModelList[i];
      if (option.toLowerCase().indexOf(filter) >= 0) {
        this.engineModelListArray.push(option)
      }
    }
  }

  // selectSearchEngineType(value:string){
  //   let filter = value.toLowerCase();
  //   for(let i = 0; i < this.engineTypeList.length; i++ ){
  //     let option = this.engineTypeList[i];
  //     if(option.toLowerCase().indexOf(filter) >= 0){
  //       this.engineTypeListArray.push(option)
  //     }
  //   }
  // }

  selectSearchApplicationType(value: string) {
    let filter = value.toLowerCase();
    for (let i = 0; i < this.applicationTypeList.length; i++) {
      let option = this.applicationTypeList[i];
      if (option.toLowerCase().indexOf(filter) >= 0) {
        this.applicationTypeListArray.push(option)
      }
    }
  }

  selectSearchDistanceUom(value: string) {
    let filter = value.toLowerCase();
    for (let i = 0; i < this.distanceUOMList.length; i++) {
      let option = this.distanceUOMList[i];
      if (option.toLowerCase().indexOf(filter) >= 0) {
        this.distanceUOMListArray.push(option)
      }
    }
  }

  clearSearchDistanceUom() {
    this.distanceUOMListArray = this.distanceUOMList;
  }
  clearSearchUnitMake() {
    this.unitMakeArray = this.unitMakeList;
  }

  clearSearchFluidBrand() {
    this.fluidBrandForOilListArr = this.fluidBrandForOilList;
    this.oilFluidBrand = '';
  }

  clearSearchFluidProduct() {
    this.fluidProductForOilListArr = this.fluidProductForOilList;
    this.oilFluidProduct = '';
  }

  clearSearchOilFilterProduct() {
    this.filterProductForOilListArr = this.filterProductForOilList;
    this.oilfilterProduct = '';
  }

  clearSearchCoolantFluidBrand() {
    this.fluidBrandForCoolantListArr = this.fluidBrandForCoolantList;
    this.coolantFluidBrand = '';
  }

  clearSearchCoolantFluidProduct() {
    this.fluidProductForCoolantListArr = this.fluidProductForCoolantList;
    this.coolantFluidProduct = '';
  }

  clearSearchCoolantFilterProduct() {
    this.filterProductForCoolantListArr = this.filterProductForCoolantList;
    this.coolantFilterProduct = '';
  }

  clearSearchFuelFilterProduct() {
    this.filterProductForFuelListArr = this.filterProductForFuelList;
    this.fuelFilterProduct = '';
  }

  clearSearchDEFFluidBrand() {
    this.fluidBrandForDefListArr = this.fluidBrandForDefList;
    this.defFluidBrand = '';
  }

  clearSearchDEFFluidProduct() {
    this.fluidProductForDefListArr = this.fluidProductForDefList;
    this.defFluidProduct = '';
  }

  clearSearchEngineMake() {
    this.engineMakeListArray = this.engineMakeList;
  }
  clearSearchApplicationType() {
    this.applicationTypeListArray = this.applicationTypeList;
  }
  clearSearchEngineYear() {
    this.engineYearListArray = this.engineYearList;
  }
  clearSearchUnitYear() {
    this.unitYearListArray = this.unitYearList;
  }
  clearSearchEngineModel() {
    this.engineModelListArray = this.engineModelList;
  }


  // clearSearchEngineType(){
  //   this.engineTypeListArray = this.engineTypeList;
  // }

  validateGSN() {
    if (this.editEquipForm.controls['gsn'].value != '') {
      this.editEquipForm.controls['isChecked'].enable();
    } else {
      this.editEquipForm.patchValue({
        isChecked: false,

      });
      this.editEquipForm.controls['isChecked'].disable();
    }
  }
  checkValue(e) {
    if (e.target.checked) {
      const dVal = this.editEquipForm.controls['gsn'].value;
      this.editEquipForm.controls['unitId'].setValue(dVal);
    } else {
      this.editEquipForm.patchValue({
        unitId: this.primaryUnitIdAsGSN,
      });

    }
  }


  validateEngineMakeDropdown() {
    if (this.editEquipForm.controls["engineMake"].value != '' && (this.editEquipForm.controls["engineModel"].value == '') && (this.editEquipForm.controls["engineModelName"].value == '')) {
      this.engineModelField = true;
      this.editEquipForm.controls['engineModel'].setValidators([Validators.required]);
      this.isFormValid = false;
      this.editEquipForm.controls['engineModel'].enable();
      this.editEquipForm.controls['engineModelNotListed'].enable();
      this.engineModelHasUnknownError = true;
      this.isFormValid = false;
    } else if ((this.editEquipForm.controls["engineMakeName"].value != '' || this.editEquipForm.controls["engineMake"].value != '' || this.editEquipForm.controls["engineModel"].value != '' || this.editEquipForm.controls["engineModelName"].value != '') && this.editEquipForm.controls["engineMakeNotListed"].value == true) {
      this.editEquipForm.patchValue({
        engineModelNotListed: true,
      });
      this.engineModelField = true;
      this.engineModelHasUnknownError = true;
      this.editEquipForm.controls['engineModel'].disable();
      this.editEquipForm.controls['engineModelNotListed'].enable();
      this.editEquipForm.controls["engineModelNotListed"].value == true;
      this.isFormValid = false;
      this.editEquipForm.controls['engineModelName'].setValidators([Validators.required]);
    } else {
      this.engineModelField = false;
      this.engineModelHasError = false;
      this.engineModelHasUnknownError = false;
    }

    if ((this.editEquipForm.controls["engineModel"].value != '' || this.editEquipForm.controls["engineModelName"].value != '' || this.editEquipForm.controls["engineModelNotListed"].value == true) && (this.editEquipForm.controls["engineMake"].value == '') && (this.editEquipForm.controls["engineMakeName"].value == '') && (this.editEquipForm.controls["engineModelName"].value != '' || this.editEquipForm.controls["engineMakeNotListed"].value == false)) {
      this.engineMakeHasError = true;
      this.editEquipForm.controls['engineMake'].setValidators([Validators.required]);
      // this.engineModelHasUnknownError = true;
      this.isFormValid = false;
    }

    let enMake;
    if (!this.editEquipForm.controls.engineMakeNotListed.value) {
      if (this.editEquipForm.controls.engineMake.value == null ||
        this.editEquipForm.controls.engineMake.value == undefined ||
        this.editEquipForm.controls.engineMake.value == '') {
        this.engineMakeHasError = true;
        this.isFormValid = false;
      } else {
        this.engineMakeHasError = false;
      }
    } else {
      this.engineMakeHasError = false;
      if (this.editEquipForm.controls.engineMakeName.invalid) {
        this.engineMakeHasError = true;
      } else {
        this.engineMakeHasError = false;
      }

    }


    enMake = this.engMakeModelList.find(i => i.key == this.editEquipForm.controls["engineMakeName"].value.toUpperCase())

    if (enMake != undefined && enMake.key == this.editEquipForm.controls["engineMakeName"].value.toUpperCase()) {
      this.engineMakeHasListedError = true;
    } else {
      this.engineMakeHasListedError = false;
    }

    // console.log(enMake, 'enmake')
    // console.log(this.editEquipForm.controls["engineMakeName"].value, 'engineMakeName')

  }


  validateEngineModelDropdown() {

    if ((this.editEquipForm.controls["engineModel"].value != '' || this.editEquipForm.controls["engineModelName"].value != '' || this.editEquipForm.controls["engineModelNotListed"].value == true) && (this.editEquipForm.controls["engineMake"].value == '') && (this.editEquipForm.controls["engineMakeName"].value == '')) {
      this.engineMakeHasError = true;
      this.editEquipForm.controls['engineMake'].setValidators([Validators.required]);
      // this.engineModelHasUnknownError = true;
      this.isFormValid = false;
    }


    let enModel;
    if (!this.editEquipForm.controls.engineModelNotListed.value) {
      if (this.editEquipForm.controls.engineModel.value == null ||
        this.editEquipForm.controls.engineModel.value == undefined ||
        this.editEquipForm.controls.engineModel.value == '') {
        this.engineModelHasError = true;
        this.isFormValid = false;
      } else {
        //this.engineModelHasError = false;
        this.engineModelHasUnknownError = false;
      }
    } else {
      this.engineModelHasUnknownError = false;
      if (this.editEquipForm.controls.engineModelName.invalid) {
        this.engineModelHasUnknownError = true;
      } else {
        this.engineModelHasUnknownError = false;
      }

    }


    enModel = this.engMakeModelList.find(i => i.value == this.editEquipForm.controls["engineModelName"].value.toUpperCase())

    if (enModel != undefined && enModel.value == this.editEquipForm.controls["engineModelName"].value.toUpperCase()) {
      this.engineModelHasListedError = true;
    } else {
      this.engineModelHasListedError = false;
    }


  }

  validateUnitMakeDropdown() {
    let unMake;
    if (!this.editEquipForm.controls.unitMakeNotListed.value) {
      if (this.editEquipForm.controls.unitMake.value == null ||
        this.editEquipForm.controls.unitMake.value == undefined ||
        this.editEquipForm.controls.unitMake.value == '') {
        this.unitMakeHasError = true;
        this.isFormValid = false;
      } else {
        this.unitMakeHasError = false;
      }
    } else {
      this.unitMakeHasError = false;
      if (this.editEquipForm.controls.unitMakeName.invalid && this.editEquipForm.controls.unitMake.value == '') {
        this.unitMakeHasError = true;
      } else {
        this.unitMakeHasError = false;
      }

    }


    unMake = this.unitMakeList.find(i => i == this.editEquipForm.controls["unitMakeName"].value.toUpperCase())

    if (unMake == this.editEquipForm.controls["unitMakeName"].value.toUpperCase()) {
      this.unitMakeHasListedError = true;
    } else {
      this.unitMakeHasListedError = false;
    }


  }

  validateApplicationTypeDropdown() {
    let appType;

    if (this.editEquipForm.controls["applicationType"].value == 'HOSPITAL' || this.editEquipForm.controls["applicationType"].value == 'POWER GENERATION' || this.editEquipForm.controls["applicationType"].value == 'STAND-BY POWER') {
      let element = document.getElementById('esn');
      console.log(element.classList);
      element.classList.remove('ng-touched', 'ng-dirty', 'ng-invalid');
      this.gsnMadatory = true;
      this.esnMadatory = false;
      //this.isFormValid = false;
      this.editEquipForm.controls['gsn'].setValidators([Validators.required]);
      this.editEquipForm.controls['gsn'].updateValueAndValidity();
      this.editEquipForm.controls["esn"].setValue('');
      this.editEquipForm.controls['esn'].clearValidators();
      this.editEquipForm.controls['esn'].updateValueAndValidity();

    } else {      
      let element = document.getElementById('gsn');
      if(element !== undefined && element !== null){
        console.log(element.classList);
        element.classList.remove('ng-touched', 'ng-dirty', 'ng-invalid');
        this.esnMadatory = true;
        this.gsnMadatory = false;
        // this.isFormValid = false;
        this.editEquipForm.controls['esn'].setValidators([Validators.required]);
        this.editEquipForm.controls['esn'].updateValueAndValidity();
        this.editEquipForm.controls["gsn"].setValue('');
        this.editEquipForm.controls['gsn'].clearValidators();
        this.editEquipForm.controls['gsn'].updateValueAndValidity();
      }
    }
    if (!this.editEquipForm.controls.applicationTypeNotListed.value) {
      if (this.editEquipForm.controls.applicationType.value == null ||
        this.editEquipForm.controls.applicationType.value == undefined ||
        this.editEquipForm.controls.applicationType.value == '') {
        this.applicationTypeHasError = true;
        this.isFormValid = false;
      } else {
        this.applicationTypeHasError = false;
      }
    } else {
      this.applicationTypeHasError = false;
      if (this.editEquipForm.controls.applicationTypeName.invalid && this.editEquipForm.controls.applicationType.value == '') {
        this.applicationTypeHasError = true;
      } else {
        this.applicationTypeHasError = false;
      }
    }

    appType = this.applicationTypeList.find(i => i == this.editEquipForm.controls["applicationTypeName"].value.toUpperCase())

    if (appType == this.editEquipForm.controls["applicationTypeName"].value.toUpperCase()) {
      this.applicationTypeHasListedError = true;
    } else {
      this.applicationTypeHasListedError = false;
    }


  }

  onCheckBoxChangedEngineMake(event, engineMake: string, engineMakeName: string) {

    if ((this.editEquipForm.controls["engineModel"].value != '' || this.editEquipForm.controls["engineModelName"].value != '' || this.editEquipForm.controls["engineModelNotListed"].value == true) && (this.editEquipForm.controls["engineMake"].value == '')) {
      this.engineMakeHasError = true;
      this.editEquipForm.controls['engineMake'].setValidators([Validators.required]);
      // this.engineModelHasUnknownError = true;
      this.isFormValid = false;
    }

    console.log('event : ' + event.target.checked);
    if (event.target.checked) {
      this.editEquipForm.controls[engineMakeName].setValidators([Validators.required]);
      this.isFormValid = false;
      this.editEquipForm.patchValue({
        [engineMake]: '',
      });

      //  this.editEquipForm.controls[engineMakeName].setValidators([Validators.required]);
      //  this.editEquipForm.controls[engineMakeName].updateValueAndValidity();

      this.editEquipForm.controls[engineMake].disable();
    } else {
      this.editEquipForm.controls[engineMakeName].clearValidators();
      this.editEquipForm.controls[engineMakeName].updateValueAndValidity();

      if (this.mode == 'add') {
        this.editEquipForm.patchValue({
          [engineMake]: '',
        });
      } else {
        this.editEquipForm.patchValue({
          // [engineMakeName]: '',
        });
      }
      this.editEquipForm.controls[engineMake].enable();
    }

  }

  onCheckBoxChangedEngineModel(event, engineModel: string, engineModelName: string) {
    console.log('event : ' + event);
    if (event.target.checked === true) {

      this.editEquipForm.patchValue({
        [engineModel]: '',
      });

      // this.editEquipForm.controls[engineModelName].setValidators([Validators.required]);
      // this.editEquipForm.controls[engineModelName].updateValueAndValidity();

      this.editEquipForm.controls[engineModel].disable();
    } else {
      this.editEquipForm.controls[engineModelName].clearValidators();
      this.editEquipForm.controls[engineModelName].updateValueAndValidity();
      if (this.mode == 'add') {
        this.editEquipForm.patchValue({
          [engineModel]: '',
        });
      } else {
        this.editEquipForm.patchValue({
          // [engineModelName]: '',
        });
      }
      this.editEquipForm.controls[engineModel].enable();
    }

  }

  onCheckBoxChangedUnitMake(event, unitMake: string, unitMakeName: string) {
    console.log('event : ' + event);
    if (event.target.checked === true) {
      this.editEquipForm.patchValue({
        [unitMake]: '',
      });
      this.editEquipForm.controls[unitMakeName].setValidators([Validators.required]);
      this.editEquipForm.controls[unitMakeName].updateValueAndValidity();

      this.editEquipForm.controls[unitMake].disable();
    } else {
      this.editEquipForm.controls[unitMakeName].clearValidators();
      this.editEquipForm.controls[unitMakeName].updateValueAndValidity();

      this.editEquipForm.patchValue({
        // [unitMakeName]: '',
      });
      this.editEquipForm.controls[unitMake].enable();
    }

  }

  onCheckBoxChangedApplicationType(event, applicationType: string, applicationTypeName: string) {
    console.log('event : ' + event);

    if (event.target.checked === true) {
      this.editEquipForm.patchValue({
        [applicationType]: '',
      });
      this.editEquipForm.controls[applicationTypeName].setValidators([Validators.required]);
      this.editEquipForm.controls[applicationTypeName].updateValueAndValidity();

      this.editEquipForm.controls[applicationType].disable();
    } else {
      this.editEquipForm.controls[applicationTypeName].clearValidators();
      this.editEquipForm.controls[applicationTypeName].updateValueAndValidity();

      this.editEquipForm.patchValue({
        // [applicationTypeName]: '',
      });
      this.editEquipForm.controls[applicationType].enable();
    }
  }


  initialDropdownValuesEngineMake() {
    this.engineMakeListArray = this.dropdownForEngineList.engineMakeList;
    this.engineModelListArray = this.dropdownForEngineList.engineModelList;
  }

  clearEngine() {
    this.editEquipForm.patchValue({
      engineMake: '',
      engineMakeName: '',
      engineModelName: '',
      engineModel: '',

    });
  }

  selectComponentTab(component: any) {
    console.log(component);    
    this.selectedTab = component;    
    console.log(this.selectedTab)
    //this.componentAbbrName = this.newArr[i].componentId;
  }

  findIfComponentChecked(component:any) {
    let val = this.componentTypeList.includes(component);
    console.log(val);
    
  }

  // get componentArray() {
  //   return this.editEquipForm.get('components').value as FormGroup;
  // }

  addComponent() {    
    this.addComponentForm = true;
    this.editEquipForm.controls['selectedComponent'].enable();
    this.editEquipForm.controls["selectedComponent"].setValue('');
  }
clearComponentForm(){
    this.editEquipForm.controls["componentMake"].setValue('');
    this.editEquipForm.controls["componentModel"].setValue('');
    this.editEquipForm.controls["componentYear"].setValue('');
    this.editEquipForm.controls["engineMake"].setValue('');
    this.editEquipForm.controls["engineModel"].setValue('');
    this.editEquipForm.controls["engineMakeName"].setValue('');
    this.editEquipForm.controls["engineModelName"].setValue('');
    this.editEquipForm.controls["engineYear"].setValue('');
    this.editEquipForm.controls["secondaryUnitId"].setValue('');
    this.editEquipForm.controls.secondaryUnitId.markAsUntouched();
    this.editEquipForm.controls.secondaryUnitId.markAsPristine();
    this.editEquipForm.controls["serialNo"].setValue('');
    this.editEquipForm.controls["distanceUom"].setValue('');
    this.setValueEngineMake();
    // this.setValueFluidOil();
    // this.setValueFluidCoolant();
    // this.setValueFluidFuel();
    // this.setValueFluidDef();
    // this.setValueFilterOil();
    // this.setValueFilterCoolant();
    // this.setValueFilterFuel();
    this.clearFilterCoolant();
    this.clearFilterFuel();
    this.clearFilterOil();    
    this.clearFluidCoolant();
    this.clearFluidOil();
    this.clearFluidFuel();
    this.clearFluidDef();
}
  selectComponent(component: string, index: number) {
    if (this.mode == 'edit') {
      this.selectionComponentIndexValue = (index !== undefined)? 1 : 0;
    }
    console.log("index value : ", index); 
    this.selectedComponentsList = []; 
    this.enableOtherTab = false; 
    this.selectedTab= component; 
    console.log("this.selectedTab : ", this.selectedTab);
    this.enableSave = false;
    // if(this.editEquipForm.controls["bulkTankFuelStorage"].value == true && this.editEquipForm.controls['secondaryUnitId'].value !== ''){
    //   this.enableSave = true;
    // }
    console.log("this.editEquipForm.controls['bulkTankFuelStorage'].value",this.editEquipForm.controls["bulkTankFuelStorage"].value);
    const componentAbbrName = this.componentTypeList.filter(row => {
      return (row.componentType.toLowerCase() == component.toLowerCase());
    });  
    // const abbrNumber = this.newArr.filter(row => {
    //   return (row.componentName == component);
    // });  
    // let newAbbrNo;
    // if(!this.editComponentClicked){
    //   newAbbrNo = Number(abbrNumber.length + 1); 
    // } else {
    //   newAbbrNo = this.newArr[this.editComponentIndex].componentIndex; 
    // }
      
    const secondaryIdNew =  (this.editEquipForm.controls["secondaryUnitId"].value != '' && this.editComponentClicked) ? '-' + this.editEquipForm.controls["secondaryUnitId"].value: '';
    this.componentAbbrName = '';
    this.componentAbbrName = this.editEquipForm.controls["unitId"].value + '-' + componentAbbrName[0].abbreviation + secondaryIdNew; 
    
    this.selectedComponentsList.push(this.selectedTab);    
    if(this.selectedTab.toUpperCase() == 'ENGINE'){
      this.enableEngineTab = true;
      this.clearComponentForm();
    } else {
      this.enableOtherTab = true;
      this.clearComponentForm();
    }    
  }
  enableAddComponent(){
    this.enableAddComponents = false;
    console.log("this.editEquipForm.controls['applicationTypeName'].value",this.editEquipForm.controls["applicationTypeName"].value)
    if((this.editEquipForm.controls["companyId"].value !== '' && (this.editEquipForm.controls["applicationType"].value !== '' || this.editEquipForm.controls["applicationTypeName"].value !== '') && this.editEquipForm.controls["unitId"].value !== '' && this.editEquipForm.controls["equipSerialNo"].value !== '' && (this.editEquipForm.controls["unitMake"].value !== '' || this.editEquipForm.controls["unitMakeName"].value !== '') && this.editEquipForm.controls["unitModel"].value !== '')
    || (this.editEquipForm.controls["companyId"].value !== '' && this.editEquipForm.controls["unitId"].value !== '' &&  this.editEquipForm.controls["equipSerialNo"].value !== '' && this.editEquipForm.controls["bulkTankFuelStorage"].value === true )){
      this.enableAddComponents = true;
    }
  }
  /*---------------------------Edit component -----------------------------*/
  
  goToEdit(arr,i) { 
    this.editComponentClicked = true;
    if (this.mode == 'edit') {
      console.log("Edit Scenario : ", this.newArr[i].componentName);
      this.enableAddComponent();
      this.addComponent();
      this.selectComponent(this.lastComponent.componentType, 0);
      this.editEquipForm.controls['selectedComponent'].disable();
      console.log("this.editComponentClicked : ", this.editComponentClicked);
      console.log("this.newArr[i] : ", this.newArr[i]);
      if(this.editComponentClicked) {
        if(this.initialData[i] !== undefined) {
          this.initialData[i].fluidForOil.fluidBrand = this.newArr[i].fluidBrandForOil;
          this.initialData[i].fluidForOil.fluidProduct = this.newArr[i].fluidProductForOil;
          this.initialData[i].fluidForOil.fluidGrade = this.newArr[i].fluidGradeForOil;
          this.initialData[i].fluidForOil.filterBrand = this.newArr[i].filterBrandForOil;
          this.initialData[i].fluidForOil.filterProduct = this.newArr[i].filterProductForOil;
        }
      }
      console.log("this.initialData[i] : ",this.initialData[i]);
      this.setRecordInfoOnEdit(this.initialData, i)
      this.editEquipForm.patchValue({
        'selectedComponent': this.newArr[i].componentName
      });
      this.enableSave = true;
    }
    this.selectedTab = '';
    this.enableEngineTab = false;
    this.enableOtherTab = false; 
    this.editComponentIndex = i;
    const selectedRow = this.newArr[i];
    this.selectedTab = this.newArr[i].componentName;
    this.componentAbbrName = '';   
    this.componentAbbrName = (this.editComponentClicked)?selectedRow.componentId : this.componentAbbrName; 
    if(selectedRow.componentName.toUpperCase() == 'ENGINE'){
        this.enableEngineTab = true;
        this.clearComponentForm();
      } else if(selectedRow.componentName != ''){
         this.enableOtherTab = true;
         this.clearComponentForm();
      }      
    this.editEquipForm.patchValue({
      'secondaryUnitId': this.newArr[i].secondaryId,
      'distanceUom': this.newArr[i].unitMeasure,
      'serialNo': this.newArr[i].serialNo,

      'fluidTypeForOil': this.newArr[i].fluidTypeForOil,
      'fluidBrandForOil': this.newArr[i].fluidBrandForOil,
      'fluidProductForOil': this.newArr[i].fluidProductForOil,
      'fluidGradeForOil': this.newArr[i].fluidGradeForOil,
      'filterBrandForOil':this.newArr[i].filterBrandForOil,
      'filterProductForOil':this.newArr[i].filterProductForOil,

      'fluidTypeForCoolant': this.newArr[i].fluidTypeForCoolant,
      'fluidBrandForCoolant': this.newArr[i].fluidBrandForCoolant,
      'fluidProductForCoolant': this.newArr[i].fluidProductForCoolant,
      'fluidGradeForCoolant': this.newArr[i].fluidGradeForCoolant,
      'filterBrandForCoolant':this.newArr[i].filterBrandForCoolant,
      'filterProductForCoolant':this.newArr[i].filterProductForCoolant,

      'fluidTypeForFuel': this.newArr[i].fluidTypeForFuel,
      'fluidBrandForFuel': this.newArr[i].fluidBrandForFuel,
      'fluidProductForFuel': this.newArr[i].fluidProductForFuel,
      'fluidGradeForFuel': this.newArr[i].fluidGradeForFuel,
      'filterBrandForFuel':this.newArr[i].filterBrandForFuel,
      'filterProductForFuel':this.newArr[i].filterProductForFuel,

      'fluidTypeForDef': this.newArr[i].fluidTypeForDef,
      'fluidBrandForDef': this.newArr[i].fluidBrandForDef,
      'fluidProductForDef': this.newArr[i].fluidProductForDef,
      'fluidGradeForDef': this.newArr[i].fluidGradeForDef,
      
      'componentId': this.newArr[i].componentId,
      'isOilChecked': this.newArr[i].isOilChecked,
      'isFuelChecked': this.newArr[i].isFuelChecked,
      'isCoolantChecked': this.newArr[i].isCoolantChecked,
      'isDefChecked': this.newArr[i].isDefChecked,
      'engineMakeNotListed': this.newArr[i].engineMakeNotListed,
      'engineModelNotListed':this.newArr[i].engineModelNotListed
    });
    if(this.mode == 'add') {
      if(this.newArr[i].fluidTypeForOil != '') {
        this.enableFluidOil();
		    this.enableFilterOil();
      } else {
        this.disableFluidOil();
		    this.disableFilterOil();
      }
      if(this.newArr[i].fluidTypeForCoolant != '') {
        this.enableFluidCoolant();
		    this.enableFilterCoolant();		
      } else {
        this.disableFluidCoolant();
		    this.disableFilterCoolant();
      }
      if(this.newArr[i].fluidTypeForFuel != '') {
        this.enableFluidFuel();
		    this.enableFilterFuel();
      } else {
        this.disableFluidFuel();
		    this.disableFilterFuel();
      }
      if(this.newArr[i].fluidTypeForDef != '') {
        this.enableFluidDef();
      } else {
        this.enableFluidDef();
      }
    }
    const engineMakeAddNewName = (this.newArr[i].engineMakeNotListed == true)? this.newArr[i].Make : '';
    const engineModelAddNewName = (this.newArr[i].engineModelNotListed == true)? this.newArr[i].Model : '';
    if(selectedRow.componentName.toUpperCase() == 'ENGINE') {
      this.editEquipForm.patchValue({
        engineType: 1,
        engineModel: this.newArr[i].Model,
        engineMake: this.newArr[i].Make,
        engineMakeName:engineMakeAddNewName,
        engineModelName:engineModelAddNewName,
        engineYear:this.newArr[i].Year,
        engineMakeNotListed: this.newArr[i].engineMakeNotListed,
        engineModelNotListed: this.newArr[i].engineModelNotListed
      });
    } else {
      this.editEquipForm.patchValue({
        engineType: 1,
        componentModel: this.newArr[i].Model,
        componentMake: this.newArr[i].Make,
        componentYear:this.newArr[i].Year,
      });
    }
  }

  scroll(){    
      let element = document.querySelector('#scroller');      
        element.scrollIntoView({behavior: 'smooth', block:'center'});
        element = null;
    
  }
  /* -------------------------Delete confirmation popup------------------------- */
  confirmDelete(i) {
    this.deleteComponentRecord = true;
    this.componentIndex = i;
  }

  cancelDelete() {
    this.deleteComponentRecord = false;
  }

  deleteComponent(){
    if(this.mode == 'edit'){
      if(this.componentEditResponse[this.componentIndex]!== undefined) {
        if(this.componentEditResponse[this.componentIndex].equipmentId!== undefined) {
          if(this.componentEditResponse[this.componentIndex].equipmentId!== '') {
            const indexValue = Number(this.componentIndex);
            let _data = {
              "equipmentId": (this.componentEditResponse[this.componentIndex]!== undefined)?this.componentEditResponse[this.componentIndex].equipmentId : ''
            }
            this.manageFleetService.deleteComponent(_data).subscribe(
              response => {
                if (response.statusCode == "1") {
                  this.alertService.success(response.statusMessage);
                  this.componentEditResponse.splice(indexValue, 1);
                  this.newArr.splice(indexValue, 1);
                } else {
                  this.alertService.error("Component ID is already associated with Sample Report and cannot be deleted");
                  this.scrollToTop = true;
                }
              }, err => {
                this.alertService.error(err);
                this.scrollToTop = true;
              }
            );
          }
        } 
      } else{
        this.newArr.splice(Number(this.componentIndex), 1);
      }
    }
    if(this.mode == 'add') {
      this.newArr.splice(Number(this.componentIndex), 1);
    }
    this.componentIndex = '';
    this.deleteComponentRecord = false;  
    if(this.newArr.length == 0){
      this.showGrid = false;
    }
  }
  setValueEngineMake(){
    // let element = document.getElementById('engineMakeName');
    //   console.log(element.classList);
    if(this.mode == 'add' || this.mode == 'edit'){
    this.initialDropdownValuesEngineMake();
    this.editEquipForm.patchValue({
      engineMake: '',
      engineMakeName: '',
      engineModelName:'',
      engineModel:'',
      engineModelNotListed: false,
      engineMakeNotListed: false,
    });
    this.editEquipForm.controls['engineModelNotListed'].disable();
    this.editEquipForm.controls['engineMakeNotListed'].enable();
    this.editEquipForm.controls["engineModelNotListed"].value == false;
    this.editEquipForm.controls["engineMakeNotListed"].value == false;
      this.editEquipForm.controls['engineModel'].disable();
      this.editEquipForm.controls['engineMake'].enable();
      this.engineModelHasUnknownError = false; 
    this.engineMakeHasError = false;
      this.engineModelField = false;
      this.editEquipForm.controls['engineModel'].clearValidators();
      this.editEquipForm.controls['engineMake'].clearValidators();
      this.editEquipForm.controls['engineMakeName'].clearValidators();
      this.editEquipForm.controls['engineModelName'].clearValidators();
      this.isFormValid = true;
    } 

    // this.engineMakeHasError = false;
    // this.engineModelField = false;
    // this.editEquipForm.controls["engineModelNotListed"].value == false;
    // this.editEquipForm.controls["engineMakeNotListed"].value == false;
    //   this.editEquipForm.controls['engineModel'].disable();
    // this.editEquipForm.controls['engineModelNotListed'].disable();
    // this.editEquipForm.controls['engineMakeNotListed'].enable();
    // this.editEquipForm.controls['engineModel'].clearValidators();
    // this.editEquipForm.controls['engineMake'].clearValidators();
    // this.editEquipForm.controls['engineMakeName'].clearValidators();
    // this.editEquipForm.controls['engineModelName'].clearValidators();
    // this.isFormValid = true;
    this.editEquipForm.patchValue({
    engineMake: '',
      engineMakeName: '',
    engineModelName:'',
    engineModel:'',
    engineModelNotListed: false,
      engineMakeNotListed: false,
    });
      this.editEquipForm.controls['engineMake'].enable();
      this.engineModelHasUnknownError = false;
  }

  enableSaveButton(){
    console.log("enableSaveButton func called");
    this.enableSave = false;
    if((this.editEquipForm.controls['secondaryUnitId'].value !== '' && this.editEquipForm.controls['distanceUom'].value !== '') || (this.editEquipForm.controls["bulkTankFuelStorage"].value == true && this.editEquipForm.controls['secondaryUnitId'].value !== '')){      
      this.enableSave = true;
    } 
  }
}
